import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import FormDialog from "./../ModalWindow/Dialog/FormDialog"
import AddSostavInOrder from "../components/addMemberInOrder/AddSostavInOrder";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Helmet } from 'react-helmet';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
const PrintButton = styled(Fab)(({ theme }) => ({
        position: 'absolute',
          top: theme.spacing(10),
          right: theme.spacing(2)
      }));


const ChampionatMembers = (props) => {
        const contextValue=useContext(Context)
        const[editOrderMember,setEditOrderMember]=useState(false)
        
        const [selectHeader,setSelectHeader]=useState(null)
        
        
        useEffect(()=>
        {
                console.log(props.orders)
                props.orders?.length>0&&setSelectHeader(props.orders[0].school_name)
                if(props.active!==undefined)
                {
                        if(props.orders.length===0)
                                props.setActive(false)
                }
        },[props.orders])

        function success_order(order_member_id,success)
        {            
            axios.post(`/api/orders/order/success/${order_member_id}/${success}`)
            .then(res=>{
                console.log(res.data.Status)
                if(res.data.Status==="Участник подтвержден")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Участник подтвержден`)
                  contextValue.setOpenAlert(true)
                }
                if(res.data.Status==="Участник отменен")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Участник отменен`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        } 
    const [NoSucsess,setNoSucsess]=useState(false)
    useEffect(
        ()=>
                {
                        console.log(NoSucsess)
                },[NoSucsess]
        )
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
      
        const handleChangePage = (event: unknown, newPage: number) => {
          setPage(newPage);
        };
      
        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };
        const componentRef = useRef();
        const handlePrint = useReactToPrint({
                content: () => componentRef.current,
              });
    return (
    <>
<Helmet>
        <title>Составы</title>
        <meta name="description" content={`Участники соревнования ${contextValue.championat.championat_name}`} />
        </Helmet>
        {/* <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton>   */}
        <FormDialog title='Изменение участника' width='md' active={editOrderMember} setActive={setEditOrderMember} children={AddSostavInOrder} orders={props.orders}/>
        <Stack direction='row' justifyContent='left'>
        <FormControlLabel sx={{ p: 1 }}
                control={<Checkbox checked={NoSucsess} onChange={(event,x)=>setNoSucsess(x)}/>}
                label="Только не подтвержденные"
                />
        </Stack>
        <Grid container spacing={3}>
        <Grid my={1} item xs={selectHeader!==null?3:12}>
        {
         Array.from(new Set(props.orders.map((obj)=>obj.school_name))).map((o,i)=>(  
                <Button  sx={{margin:"3px"}} variant={o===selectHeader?"contained":"outlined"} fullWidth onClick={()=>setSelectHeader(o)}>{o} </Button>))
        } 
        </Grid> 
        {selectHeader!==null&&<Grid item xs={9}>  
                          <TableContainer component={Paper}>
                          <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                labelRowsPerPage="Участников на странице"
                                component="div"
                                count={props.orders.filter((x)=>x.school_name===selectHeader && x.order_member_success!==2-Number(NoSucsess)).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                                nextIconButtonText="Следующая страница"
                                backIconButtonText="Предыдущая страница"
                                firstIconButtonText="Первая страница"
                                lastIconButtonText="Последняя страница"
                        />
                          <Table ref={componentRef} style={{ width: "100%", tableLayout: "auto"}}  aria-label="sticky table" size='small'  sx={{
                                      '& .MuiTableCell-sizeSmall': {
                                      padding: '1px',
                                      },
                                      }}
                              > 
                                <TableHead>
                                  <TableRow>
                                    <TableCell>№</TableCell>         
                                    <TableCell align="center"
                                    sx={{
                                        whiteSpace: "pre-wrap"
                                        }}>ФИО</TableCell>
                                    {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center">ВИД</TableCell>}
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center">ГОД РОЖДЕНИЯ</TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН"&&<TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center">РАЗРЯД ИМЕЕТ</TableCell>}
                                    {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center">ВОЗРАСТНАЯ КАТЕГОРИЯ</TableCell>}
                                    {contextValue.championat.championat_style_name!=="КПН"&&<TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center">ВЫСТУПАЕТ ПО РАЗРЯДУ</TableCell>}
                                    <TableCell align="center">ТРЕНЕРЫ</TableCell>
                                    {props.active===undefined && <TableCell align="center" minWidth="120px"><p style={{width:100}}></p> </TableCell>}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {props.orders.filter((x)=>x.school_name===selectHeader && x.order_member_success!==2-Number(NoSucsess))?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((om,i)=>(
                                      <TableRow
                                      key={om.order_member_id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">                                        
                                              <p style={{width:50}}>{i+1}   {om.order_member_success===1 && <CheckOutlinedIcon style={{ 
                                                 color: "green",
                                                fontSize: "30"}}/>}</p>
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }}align="left">
                                            <p style={{width:200}}>{om.FIO}</p>           
                                    </TableCell>   
                                    {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center">
                                            {om.sostav_type_name}                    
                                    </TableCell>  }            
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">           
                                            {om.age}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН"&&<TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center">            
                                            <p >{om.razryad_name}</p>                       
                                    </TableCell>}
                                    {contextValue.championat.championat_style_name==="Парно-групповое"&&<TableCell align="center">             
                                            {om.group_category_name}                          
                                    </TableCell>}   
                                    {contextValue.championat.championat_style_name!=="КПН"&&<TableCell align="center">             
                                            {om.razryad_short_name}                          
                                    </TableCell>}
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            }} align="center"> 
                                             <p style={{width:150}}>{om.treners}</p>  
                                    </TableCell> 

                                    {props.active===undefined && <TableCell align="center">
                                                                          
                                       {om.order_member_success===0? 
                                       <Tooltip title="Подтвердить участника">    
                                       <IconButton  aria-label="edit"  onClick={()=>success_order(om.order_member_id,1)}>
                                                <CheckOutlinedIcon/>                                        
                                        </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Отменить участника">
                                        <IconButton  aria-label="edit" onClick={()=>{success_order(om.order_member_id,0)}}>
                                        <CloseOutlinedIcon/>
                                        </IconButton>
                                        </Tooltip>
                                        }
                                        <Tooltip title="Редактировать"> 
                                        <IconButton  aria-label="edit" onClick={()=>{contextValue.setOrderMemberID(om.order_member_id);setEditOrderMember(true)}}>
                                        <EditOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        </TableCell>} 
                                      </TableRow>))}          
                                    </TableBody>
                              </Table>
                              <TableFooter>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                labelRowsPerPage="Участников на странице"
                                component="div"
                                count={props.orders.filter((x)=>x.school_name===selectHeader && x.order_member_success!==2-Number(NoSucsess)).length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                                nextIconButtonText="Следующая страница"
                                backIconButtonText="Предыдущая страница"
                                firstIconButtonText="Первая страница"
                                lastIconButtonText="Последняя страница"
                        />
        </TableFooter>
                            </TableContainer> 
                            </Grid> }
                            </Grid> 
                        </>
     )
}
 
export default ChampionatMembers;