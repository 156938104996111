import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import Championat from "../components/championat/Championat";
import logo from "./../img/Logo.png"
import Button from '@mui/material/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddChampionat from "../ModalWindow/AddChampionat/AddChampionat";
import FormDialog from "./../ModalWindow/Dialog/FormDialog"
import axios from 'axios';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p:3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  

const Championats = (props) => {
  const [championats, setChampionats] =useState([])
  const [view, setView] =useState("Список")
  const[addChampionat,setAddChamionat]=useState(false)
    const contextValue=useContext(Context)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    useEffect(()=>
      {
        axios.get(`/api/championats/${contextValue.school_id}`)
        .then(res=>{console.log(res.data); setChampionats(res.data)})
        .catch(err=>console.log(err)) 
        
      },[contextValue.setOpenAlert]);
      useEffect(()=>
      {


      },[addChampionat]);
    
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    useEffect(()=>{
      setView(localStorage.getItem('championat_view'))
    },[])

    return (       
        <>
        <Helmet>
        <title>Соревнования</title>
        <meta name="description" content="Управляйте своими соревнованиями" />
        </Helmet>      
        {addChampionat && <FormDialog width={'md'} title={"Новое соревнование"} active={addChampionat} setActive={setAddChamionat} children={AddChampionat}/> } 

            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">                           
                            
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>   
                             {contextValue.userType!==2&&<Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
                                  onClick={()=>{
                                  contextValue.setChampionatID(null)  
                                  setAddChamionat(true)}}
                                  fullWidth
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px'}}
                                >Новое соревнование</Button>  }
                                <Box sx={{justifyContent:"flex-end"}}>
                                <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={(event, newValue)=>
                        {                          
                          localStorage.setItem('championat_view', newValue);
                          setView(newValue)
                        }}
                        aria-label="Platform"
                        sx={{
                          my:1,
                            display: 'grid',                    
                            gridAutoColumns: '1fr',
                            gridTemplateColumns: 'repeat(2, 1fr)'            
                        }}
                        >
                        <ToggleButton value='Список'>
                        <ReorderOutlinedIcon/>
                            </ToggleButton>
                        <ToggleButton value='Карточки'>
                          <GridViewOutlinedIcon/>
                        </ToggleButton>
                      </ToggleButtonGroup>
                                </Box>                        
                              <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"                               
                              >
                               
                                <Tab label="Все соревнования" {...a11yProps(0)} />                                
                                <Tab label="Мои соревнования" {...a11yProps(1)} />
                                <Tab label="Соревнования в архиве" {...a11yProps(2)} />
                              </Tabs>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={value}
                              onChangeIndex={handleChangeIndex}
                            >
                              <TabPanel value={value} index={0} dir={theme.direction}>
                                  <ul className={view!=="Список"?"championats":""}>
                                    {championats
                                        ?.filter((x)=>x.championat_archive===0)
                                        ?.map((p,index)=>
                                            {   return <Championat 
                                                championat_id={p.championat_id} 
                                                championat_name={p.championat_name} 
                                                championat_type_name={p.championat_type_name} 
                                                championat_type_id={p.championat_type_id} 
                                                championat_style_name={p.championat_style_name}
                                                championat_style_id={p.championat_type_id}
                                                championat_start_date={p.championat_start_date}
                                                championat_end_date={p.championat_end_date}
                                                championat_final_date_order={p.championat_final_date_order}
                                                championat_adress={p.championat_adress}
                                                championat_phone={p.championat_phone}
                                                school_id={p.school_id}
                                                school_name={p.school_name}
                                                user_id={p.user_id}
                                                archive={p.order_archive}
                                                img={logo} 
                                                index={index}
                                                view={view}
                                                />
                                            })}               
                                    </ul>
                              </TabPanel>
                              <TabPanel value={value} index={1} dir={theme.direction}>
                                    <ul className={view!=="Список"?"championats":""}>
                                        {championats
                                        ?.filter((x)=>x.championat_archive===0)
                                        ?.filter((x)=>x.school_id===contextValue.school_id)
                                        ?.map((p,index)=>
                                            {
                                                return <Championat 
                                                championat_id={p.championat_id} 
                                                championat_name={p.championat_name} 
                                                championat_type_name={p.championat_type_name} 
                                                championat_type_id={p.championat_type_id}  
                                                championat_style_name={p.championat_style_name}
                                                championat_style_id={p.championat_type_id}
                                                championat_start_date={p.championat_start_date}
                                                championat_end_date={p.championat_end_date}
                                                championat_final_date_order={p.championat_final_date_order}
                                                championat_adress={p.championat_adress}
                                                championat_phone={p.championat_phone}
                                                school_id={p.school_id}
                                                school_name={p.school_name}                                                
                                                user_id={p.user_id}
                                                archive={p.order_archive}
                                                img={logo} 
                                                index={index}                                                
                                                view={view}
                                                />
                                            })} 
               
                                    </ul>
                                {/* </div> */}
                              </TabPanel>
                              <TabPanel value={value} index={2} dir={theme.direction}> 
                                    <ul className={view!=="Список"?"championats":""}>
                                        {championats
                                        ?.filter((x)=>x.championat_archive===1)
                                        ?.filter((x)=>x.school_id===contextValue.school_id)
                                        ?.map((p,index)=>
                                            {
                                                return <Championat 
                                                championat_id={p.championat_id} 
                                                championat_name={p.championat_name} 
                                                championat_type_name={p.championat_type_name} 
                                                championat_type_id={p.championat_type_id} 
                                                championat_start_date={p.championat_start_date}
                                                championat_end_date={p.championat_end_date}
                                                championat_final_date_order={p.championat_final_date_order}
                                                championat_adress={p.championat_adress}
                                                school_id={p.school_id}
                                                school_name={p.school_name}
                                                archive={p.order_archive}
                                                img={logo} 
                                                index={index}                                                
                                                view={view}
                                                />
                                            })} 
                                    </ul>
                              </TabPanel>
                            </SwipeableViews>
                          </Box>                          
                        </div>
                    </div>
                </div>
            </>
     );
}
 
export default Championats;