import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import { useNavigate } from "react-router-dom";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import axios from 'axios';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ChampionatStatistics from "./ChampionatStatistics";
import ChampionatStatisticsIndivid from "./ChampionatStatisticsIndivid";
import ChampionatMembers from "./ChampionatMembers";
import ChampionatResults from "./ChampionatResults";
import ChampionatSuds from "./ChampionatSuds";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TitleIcon from '@mui/icons-material/Title';
import EventIcon from '@mui/icons-material/Event';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddChampionat from "../ModalWindow/AddChampionat/AddChampionat";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const ChampionatPage = (props) => {
    
    const contextValue=useContext(Context)
    const [orders, setOrders] =useState([])
    const navigate = useNavigate();
    const[showStatistic,setShowStatistic]=useState(false)
    const[showSuds,setShowSuds]=useState(false)
    const [suds,setSuds]=useState([])
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
      useEffect(()=>
      {
        if(contextValue.championat_id!==null)
          contextValue.championat.championat_style_name==="Парно-групповое"?
             axios.post(`/api/orders/get_orders_sostavs/${contextValue.championat_id}`)
              .then(res=>{
                  setOrders(res.data)
                })
              .catch(err=>console.log(err)) 
              :
              axios.post(`/api/orders/get_order_sportsmens/${contextValue.championat_id}`)
              .then(res=>{
                  setOrders(res.data)
                })
              .catch(err=>console.log(err))             
        
      },[contextValue.setOpenAlert]);

      useEffect(()=>
        {
          if(contextValue.championat_id!==null)
                        axios.get(`/api/suds/all/${contextValue.championat_id}`)
                        .then(res=>{
                                console.log(res.data)
                                setSuds(res.data)
                          })
                        .catch(err=>console.log(err)) 
                
        },[contextValue.setOpenAlert])

        function BeenSud()
        {
               
                    axios.post("/api/suds/add",{
                      championat_sud_id:null,
                      championat_id:contextValue.championat_id,
                      trener_id:contextValue.trener_id,
                      sud_role_id:null})
                        .then(res=>{
                            if(res.data.Status==="Судья добавлен")
                            {                            
                                contextValue.setTypeMsg("success")
                                contextValue.setMsg("Вы добавлены как судья")
                                contextValue.setOpenAlert(true)
                            }
                            else alert(res.data.Error);
                        })
                        .catch(err =>alert(err));
                                   
              
            }
            function deleteSud(championat_sud_id)
        {            
            axios.post(`/api/suds/delete/${championat_sud_id}`)
            .then(res=>{
                console.log(res.data.Status)
                if(res.data.Status==="Судья удален")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Судья удален`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        } 
        const[addChampionat,setAddChamionat]=useState(false)
    return (       
        <>
        <Helmet>
        <title>Заявка</title>
        <meta name="description" content="Соревнование" />
      </Helmet> 
      {addChampionat && <FormDialog width={'md'} title={"Новое соревнование"} active={addChampionat} setActive={setAddChamionat} children={AddChampionat} orders={suds} setOrders={setSuds}/> } 

      {showStatistic&&<FormDialog title="Статистика" width={'md'} active={showStatistic} setActive={setShowStatistic} children={contextValue.championat.championat_style_name==="Парно-групповое"?ChampionatStatistics:ChampionatStatisticsIndivid} orders={orders.filter(x=>x.order_member_success===1)}/>}
       {showSuds&& <FormDialog title="Судейская коллегия" width={'lg'} active={showSuds} setActive={setShowSuds} children={ChampionatSuds} orders={suds}/>}
      
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                      {contextValue.trener_id!=null&& <Stack direction='row' justifyContent='right'>
                              {suds.filter(x=>x.trener_id===contextValue.trener_id).length===0?
                              <Button onClick={()=>BeenSud()}>Буду судить</Button>
                              :<Button onClick={()=>deleteSud(suds.filter(x=>x.trener_id===contextValue.trener_id)[0].championat_sud_id)}>Не буду судить</Button>}
                        </Stack>}
                        
                        {contextValue.championat_id && <>
                                                  
                        <Grid container spacing={2} sx={{my:1, p:1}}>
        <Grid
          item
          xs={12}
          md={12}
        >
                       <Paper elevation={3} sx={{ m:1, p:1 }}>
    <Box sx={{ backgroundColor: '#f5f5f5', p: 2, marginBottom:1 }}>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Cоревнование
    
    <Tooltip title="Редактировать"> 
                                        <IconButton  aria-label="edit" onClick={()=>{setAddChamionat(true)}}>
                                        <EditOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        </Typography>
</Box>
        <Stack spacing={2}>
    <Box display="flex" alignItems="center">
        <EventIcon />
        <Typography>Тип: {contextValue.championat.championat_type_name} ({contextValue.championat.championat_style_name})</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <TitleIcon />
        <Typography>Название: "{contextValue.championat.championat_name}"</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <DateRangeIcon />
        <Typography>Даты: {contextValue.championat.championat_start_date} - {contextValue.championat.championat_end_date}</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <SchoolIcon />
        <Typography>Организатор: {contextValue.school.school_name}</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <LocationOnIcon />
        <Typography>Место проведения:
            {JSON.parse(contextValue.championat.championat_adress)?.data?.country || ''}{JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.city ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.city} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.street_with_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.house_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.house ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house}` : ''}
        </Typography>
        </Box>
    </Stack>
</Paper>
                        </Grid>
                        <Grid
          item
          xs={12}
          md={12}
        >
                        <Paper elevation={3} sx={{ m:1, p:1 }}>
                        <Grid container spacing={2} sx={{my:1, p:1}}>
                        <Grid item xs={12} md={3}>
                        
                        <Button 
                                  variant="contained"
                                  startIcon={<GradingOutlinedIcon />} 
                                  size="large"
                                  key="Order"
                                  fullWidth
                                  sx={{height:'100%'}}
                                  onClick={()=>{
                                    contextValue.setOrderMemberID(null)                                    
                                    navigate("/order")}
                                  }
                                >Заявка</Button> 
                        </Grid>
                        <Grid item xs={12} md={3}>
                                     
                              <Button 
                              variant="contained" 
                              startIcon={<EventNoteOutlinedIcon/>}
                              size="large"
                              key="GetGraph"
                              fullWidth                              
                              sx={{height:'100%'}}
                              onClick={()=>{                                    
                                navigate("/graph")
                              }
                              }
                            >Стартовый протокол</Button> 
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button 
                                  variant="contained" 
                                  startIcon={<DonutLargeOutlinedIcon/>}
                                  size="large"
                                  key="getStatistic"
                                  fullWidth                                  
                                  sx={{height:'100%'}}
                                  onClick={()=>{setShowStatistic(true)}}
                                >Статистика</Button> 
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button 
                                  variant="contained" 
                                  startIcon={<GavelOutlinedIcon/>}
                                  size="large"
                                  key="getSuds"
                                  fullWidth
                                  sx={{height:'100%'}}
                                  onClick={()=>{setShowSuds(true)}}
                                >Судейская коллегия</Button> 
                        </Grid>
                        </Grid> 
                        </Paper>
                        </Grid> 
                        </Grid> 
                        </>   
                        }
                        
                            <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                            <Paper elevation={3} sx={{ m:1, p:1 }}>                                                     
                              <Tabs
                                value={value}
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"                               
                              >
                               
                                <Tab label="Участники" {...a11yProps(0)} />                                
                                <Tab label="Результаты" {...a11yProps(1)} />
                              </Tabs>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={value}
                              onChangeIndex={handleChangeIndex}
                            >
                              <TabPanel value={value} index={0} dir={theme.direction}>
                              <ChampionatMembers orders={orders}/>
                              </TabPanel>
                              <TabPanel value={value} index={1} dir={theme.direction}>
                              <ChampionatResults suds={suds}/>
                              </TabPanel>
                               
                              </SwipeableViews>
                             </Paper>
                            </Box>
                        </div>
                    </div>
                </div>
            </>
     );
}
 
export default ChampionatPage;