import { useEffect, useState ,useContext} from "react";
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddSostavInOrder from "../components/addMemberInOrder/AddSostavInOrder";
import EditMember from "../components/addMemberInOrder/AddSostavInOrder";
import OrderPrint from "./OrderPrint";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Grid from '@mui/material/Grid';
import { NavLink } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '@mui/material/Tooltip';
import AddSud from "../components/addSud/AddSud";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { styled } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TitleIcon from '@mui/icons-material/Title';
import EventIcon from '@mui/icons-material/Event';

const PrintButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(2)
}));

const ItemWithDelete = ({ text, onDelete }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body1" style={{ flexGrow: 1 }}>
        {text}
      </Typography>
      <IconButton onClick={onDelete} aria-label="delete">
        <CloseIcon />
      </IconButton>
    </div>
  );
};
const Order = (props) => {
    
    const contextValue=useContext(Context)
    const [order_id, setOrderID] =useState(null)
    const [order, setOrder] =useState([])
    const[editOrderMember,setEditOrderMember]=useState(false)    
    const[printPage,setPrintPage]=useState(false)    
    const [archive, setArchive] =useState([])
    const componentRef = useRef();    
    const [suds,setSuds]=useState([])
    // const [sudsLabel,setSudsLabel]=useState()
    const[editSud,setEditSud]=useState(false)

    useEffect(()=>
      {
        if(contextValue.championat_id!==null)
        {
        axios.post(`/api/orders/get_order_id/${contextValue.championat_id}/${contextValue.school_id}`)
        .then(res=>{
          console.log(res.data.Status)
          console.log(res.data)
          if(res.data.Status==="Получен идентификатор заявки")
          {                            
             console.log("Получен идентификатор заявки: "+res.data.order[0].order_id)
             setOrderID(res.data.order[0].order_id)
             setArchive(res.data.order[0].order_archive) 
          }
          else alert(res.data.Error);})
        .catch(err=>console.log(err)) 
      }
    },[contextValue.championat_id]);

    useEffect(()=>
      {
        if(contextValue.championat_id!==null)
                      axios.get(`/api/suds/all/${contextValue.championat_id}`)
                      .then(res=>{
                              setSuds(res.data?.filter(x=>x.school_id===contextValue.school_id && x.sud_role_id===0))
                              console.log(res.data)
                              // setSudsLabel(res.data?.filter(x=>x.school_id===contextValue.school_id && x.sud_role_id===0)?.map(x => x.FIO)?.join(', '))
                        })
                      .catch(err=>console.log(err)) 
              
      },[order_id,contextValue.setOpenAlert])

      useEffect(()=>
      {
        if(order_id!==null)
          contextValue.championat.championat_style_name!=="Парно-групповое"?
             axios.post(`/api/orders/get_order_sportsmen/${order_id}`)
              .then(res=>{
                  setOrder(res.data)                           
                  console.log("Заявка получена")  
                  console.log(res.data)
                })
              .catch(err=>console.log(err))  
            :
            axios.post(`/api/orders/get_order_sostavs/${order_id}`)
            .then(res=>{
                setOrder(res.data)                           
                console.log("Заявка получена")  
                console.log(res.data)
              })
            .catch(err=>console.log(err))  
        
      },[order_id,contextValue.setOpenAlert]);



    function deletemember(id)
    {            
        axios.post(`/api/orders/order_member/delete/${id}`)
        .then(res=>{
            console.log(res)
            if(res.data.Status==="Участник удален")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Участник удален id=${id}`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 
    function deleteSud(championat_sud_id)
    {            
        axios.post(`/api/suds/delete/${championat_sud_id}`)
        .then(res=>{
            console.log(res.data.Status)
            if(res.data.Status==="Судья удален")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Представитель команды удален`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 
    function publish_order()
    {            
        axios.post(`/api/orders/order/publish/${order_id}`)
        .then(res=>{
            console.log(res)
            if(res.data.Status==="Изменения в заявке опубликованы")
            {
              contextValue.setTypeMsg("success")
              contextValue.setMsg(`Изменения в заявке опубликованы`)
              contextValue.setOpenAlert(true)
            }
            else console.log(res.data.Status);
        })
        .catch(err =>alert(err))
    } 
    const handleDelete = (id) => {
      setSuds((prevSuds) => prevSuds.filter((sud) => sud.championat_sud_id !== id));
      deleteSud(id)
    };
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    useEffect(()=>{!editOrderMember&&contextValue.setOrderMemberID(null)},[editOrderMember])
    return (       
        <>
      {editOrderMember&&<FormDialog title='Изменение участника' width='md' active={editOrderMember} setActive={setEditOrderMember} children={EditMember} orders={order}/>}
      {printPage&&<FormDialog title='Печать заявки' width='lg' active={printPage} setActive={setPrintPage} children={OrderPrint} orders={order}/>}
      {editSud && <FormDialog title='Добавление представителя команды' width='md' active={editSud} setActive={setEditSud} children={AddSud} orders={{suds:suds,type:"soprovod"}}/>}
                        
       <Helmet>
        <title>Заявка</title>
        <meta name="description" content="Редактирование заявки" />
      </Helmet>      
      <PrintButton color="secondary" aria-label="add" onClick={()=>setPrintPage(true)}>
                  <PrintOutlinedIcon />
                </PrintButton>      
            <div class="project-details"> 
                <div className="max-w-full">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                   
                          <Grid container>
        <Grid
          item
          xs={12}
          md={8}
        >
                       <Paper elevation={3} sx={{ m:1, height: '300px',  }}>
    <Box sx={{ backgroundColor: '#f5f5f5', p: 2, marginBottom:1 }}>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Заявка на участие в соревнованиях
    </Typography>
</Box>
        <Stack spacing={2}>
    <Box display="flex" alignItems="center">
        <EventIcon />
        <Typography>Тип: {contextValue.championat.championat_type_name} ({contextValue.championat.championat_style_name})</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <TitleIcon />
        <Typography>Название: "{contextValue.championat.championat_name}"</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <DateRangeIcon />
        <Typography>Даты: {contextValue.championat.championat_start_date} - {contextValue.championat.championat_end_date}</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <SchoolIcon />
        <Typography>Организатор: {contextValue.school.school_name}</Typography>
    </Box>
    <Box display="flex" alignItems="center">
        <LocationOnIcon />
        <Typography>Место проведения:
            {JSON.parse(contextValue.championat.championat_adress)?.data?.country || ''}{JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.city ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.city} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data.street_with_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.house_type ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type} `: ''}
            {JSON.parse(contextValue.championat.championat_adress)?.data?.house ? `, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house}` : ''}
        </Typography>
        </Box>
    </Stack>
</Paper>
                        </Grid>

                        <Grid
          item
          xs={12}
          md={4}
        >
        <Paper elevation={3} sx={{m:1, height: '300px'}}>
        <Box sx={{ backgroundColor: '#f5f5f5', p: 2, marginBottom:1}}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {`${suds!==""?`Представители команды:\n`:""}`}
                        </Typography>
        </Box>
        <Box sx={{height: '200px',overflow:'auto'}}>
                        {suds.map((sud) => (<ItemWithDelete
          key={sud.championat_sud_id}
          text={sud.FIO}
          onDelete={() => handleDelete(sud.championat_sud_id)}
          
        />))}</Box>
        
        </Paper>
        </Grid>
        <Grid item xs={12}>
        <Paper elevation={3} sx={{m:1}}>
                        <Grid container>
                        <Grid item xs={12} md={4} sx={{p:1}}>
                        <Button 
                                  variant="contained"
                                  startIcon={<EmojiEventsOutlinedIcon />} 
                                  key="addOrder"
                                  fullWidth
                                  component={NavLink}
                                  to="/championat"
                                >Страница соревнования</Button> 
                        </Grid>
                        <Grid item xs={12} md={4} sx={{p:1}}>
                        {archive===0&&<Button 
                                  variant="contained"
                                  startIcon={<SaveOutlinedIcon />} 
                                  key="addOrder"
                                  fullWidth
                                  onClick={()=>publish_order()}
                                >Опубликовать заявку</Button>} 
                        </Grid>
                        <Grid item xs={12} md={4} sx={{p:1}}>
                        {archive===0&&<Button 
                                  variant="contained"
                                  startIcon={<GroupAddOutlinedIcon />} 
                                  key="addOrder"
                                  fullWidth
                                  onClick={()=>setEditSud(true)}
                                >Добавить представителя команды</Button>} 
                        </Grid>
                        </Grid>  
                        </Paper> 
                        </Grid> 
                        </Grid>                                           
                        {archive===0&&order_id!==null&&
                        <Paper elevation={3}  sx={{m:1}}>
                        <Box sx={{ backgroundColor: '#f5f5f5', p: 2, marginBottom:1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Добавить участника в заявку
                        </Typography>
                        </Box>
                        <AddSostavInOrder setOrder={setOrder} order={order} order_id={order_id} setOrderID={setOrderID}/>
                        </Paper>}
                        
                        
                        <Box
                            sx={{
                                flexgrow: 1,
                            bgcolor: 'background.paper',
                            flexWrap: 'wrap' 
                            }}>
                              
                          </Box>
                          <div  ref={componentRef}>
                          <Paper elevation={3}  sx={{m:1}}>
                          <Box sx={{ backgroundColor: '#f5f5f5', p: 2, marginBottom:1 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Участники 
                            </Typography>
                            </Box>
                          <TableContainer>
                          <TableFooter>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                labelRowsPerPage="Спортсменов на странице"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                                component="div"
                                count={order.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonText="Следующая страница"
                                backIconButtonText="Предыдущая страница"
                                firstIconButtonText="Первая страница"
                                lastIconButtonText="Последняя страница"
                                        />
                        </TableFooter>
                              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>№</TableCell>            
                                    <TableCell align="center">ФИО</TableCell>
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && <TableCell align="center">ВИД</TableCell>}
                                    <TableCell align="center">ГОД РОЖДЕНИЯ</TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center">РАЗРЯД ИМЕЕТ</TableCell>}
                                    {contextValue.championat.championat_style_name==="Парно-групповое" &&<TableCell align="center">ВОЗРАСТНАЯ КАТЕГОРИЯ</TableCell>}
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center">ВЫСТУПАЕТ ПО РАЗРЯДУ</TableCell>}
                                    {contextValue.championat.championat_style_name==="КПН" && <TableCell align="center">УРОВЕНЬ ПОДГОТОВКИ</TableCell>}
                                    <TableCell align="center">Тренеры</TableCell>
                                    {archive===0&&<TableCell sx={{minWidth:"50px"}} align="center"></TableCell>}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {order.length>0 && order?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((o,i)=>(
                                      <TableRow
                                      key={o.order_member_id}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                            {i+1}
                                    </TableCell>
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">
                                            {o.FIO}            
                                    </TableCell>   
                                    {contextValue.championat.championat_style_name==="Парно-групповое" && 
                                    <TableCell align="center">
                                            {o.sostav_type_name}                    
                                    </TableCell>}              
                                    <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">           
                                            {o.age}                    
                                    </TableCell>
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell sx={{
                                            whiteSpace: "pre-wrap"
                                            
                                            }} align="center">            
                                            {o.razryad_name}                      
                                    </TableCell>  }    
                                    {contextValue.championat.championat_style_name==="Парно-групповое" &&<TableCell align="center">             
                                            {o.group_category_name}                          
                                    </TableCell>   }
                                    {contextValue.championat.championat_style_name!=="КПН" && <TableCell align="center">             
                                            {o.razryad_short_name}                          
                                    </TableCell> }
                                    {contextValue.championat.championat_style_name==="КПН" && <TableCell align="center">             
                                            {o.kpn}                          
                                    </TableCell> } 
                                    <TableCell
                                        sx={{
                                            whiteSpace: "pre-wrap",
                                            fontSize: (theme) => `${14 - (o.treners?.split('\n')?.length || 0)}px`
                                        }}
                                        align="center"
                                    >
                                        {o.treners}
                                    </TableCell>         
                                    {archive===0&&<TableCell sx={{minWidth:"100px"}} align="center">
                                    <Tooltip title="Редактировать"> 
                                      <IconButton  aria-label="edit" onClick={()=>{contextValue.setOrderMemberID(o.order_member_id);setEditOrderMember(true)}}>
                                        <EditOutlinedIcon />
                                        </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Удалить участника">
                                      <IconButton aria-label="delete" 
                                           onClick={()=>deletemember(o.order_member_id)}
                                      >
                                        <DeleteOutlineOutlinedIcon />
                                      </IconButton>
                                     </Tooltip>
                                      </TableCell>}
                                      </TableRow>))}          
                                    </TableBody>
                              </Table>
                              <TableFooter>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                labelRowsPerPage="Спортсменов на странице"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                                component="div"
                                count={order.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                nextIconButtonText="Следующая страница"
                                backIconButtonText="Предыдущая страница"
                                firstIconButtonText="Первая страница"
                                lastIconButtonText="Последняя страница"
                                          />
                          </TableFooter>
                            </TableContainer> 
                            </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </>
     );
}
 
export default Order;