import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useContext,useEffect,useState } from "react";
import axios from 'axios';
import {Context} from "../context";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
    input: {
      '& input[type=number]': {
          '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
      }
    },
  });



const AddResult = (props) => {
    const classes = useStyles();

    const contextValue=useContext(Context)
    
    const [values,setValues] = useState(null)

    const [score,setScore] = useState ({score:null})

    
    function deleteitemfromgraph()
    {         
           axios.post(`/api/orders/order/success/${props.orders.order_member_id}/0`)
           .then(res=>{
               if(res.data.Status==="Участник отменен")
               {
                 contextValue.setTypeMsg("success")
                 contextValue.setMsg(`Участник отменен`)
                 contextValue.setOpenAlert(true) 
                 
                 props.setOrders(null)                 
                 props.setActive(false)
               }
               else console.log(res.data.Status);
           })
           .catch(err =>alert(err))
           
      //  setOrder(graph.filter((x) => x.order_member_id !== selectItem.order_member_id))
    }

    function deleteResult()
    {         
           axios.post(`/api/orders/order/delete_result/${props.orders.championat_result_id}`)
           .then(res=>{
               if(res.data.Status==="Результат удален")
               {
                 contextValue.setTypeMsg("success")
                 contextValue.setMsg(`Результат удален`)
                 contextValue.setOpenAlert(true) 
                 
                 props.orders.championat_result_id=null
                            props.orders.score=null
                            props.orders.score_json=null
                 props.setOrders(props.orders)                 
                 props.setActive(false)
               }
               else console.log(res.data.Status);
           })
           .catch(err =>alert(err))
           
      //  setOrder(graph.filter((x) => x.order_member_id !== selectItem.order_member_id))
    }
    useEffect(()=>{
        if(props.orders!==null) {
        console.log(props.orders)
        setValues({...values,championat_result_id:props.orders.championat_result_id,
            championat_id:props.orders.championat_id,
            order_member_id:props.orders.order_member_id,
            composition_type_id:props.orders.composition_type_id,
            score:props.orders.score,
            header:props.orders.Header.replace("Комбинированное","").replace("Балансовое","").replace("Темповое","").trim(),
            score_json:props.orders.score_json})
            if(props.orders.score_json!==null && props.orders.score_json!==undefined)
                {
                let json=JSON.parse(props.orders.score_json)
                console.log(json)
                setScore(JSON.parse(props.orders.score_json))  
                }
                
              }
    },[props.orders])

    useEffect(()=>{console.log(values)},[values])
    useEffect(()=>{console.log(score)},[score])
    const average = arr => {
        let sum=0
        for( var i = 0; i < arr.length; i++ ){
            sum += parseFloat( arr[i], 10 ); //don't forget to add the base
        }
        let result=parseFloat((sum/arr.length).toFixed(2))
        return isNaN(result)?0:result;
    }

    useEffect(()=>
        {
            let sum=[]
            score?.ti1?.toString().trim()!==0 &&  sum.push(score?.ti1)
            score?.ti2?.toString().trim()!==0 &&  sum.push(score?.ti2)
            score?.ti3?.toString().trim()!==0 &&  sum.push(score?.ti3)
            score?.ti4?.toString().trim()!==0 &&  sum.push(score?.ti4)           

            if (sum.length===3) sum.push(score?.psbti)
            if (sum.length===4)
                {                                    
                    sum=sum.sort((a, b)=>a-b)
                    sum.pop()
                    sum.splice(0,1)
                }
            setScore(prevState => ({...prevState,avgti:average(sum)})) 
        },[score?.ti1,score?.ti2,score?.ti3,score?.ti4,score?.psbti])

    useEffect(()=>
        {
            setScore(prevState=>({...prevState,avgtix2:score?.avgti*2})) 
        },[score?.avgti])

    useEffect(()=>
        {
            console.log(score)
            let result = 0
            if(score?.avgtix2!==undefined) result+=score?.avgtix2
            if(score?.ertime!==undefined) result-=score?.ertime
            if(score?.spec!==undefined) result-=score?.spec
            if(score?.warn!==undefined) result-=score?.warn

            console.log(result)
            setScore(prevState=>({...prevState,scoreisp:parseFloat(result.toFixed(2))}))
        },[score?.avgtix2,score?.ertime,score?.spec,score?.warn])

    useEffect(()=>
        {
            let sum=[]
            score?.art1?.toString().trim()!=="" && sum.push(score?.art1)
            score?.art2?.toString().trim()!=="" && sum.push(score?.art2)
            score?.art3?.toString().trim()!=="" && sum.push(score?.art3)
            score?.art4?.toString().trim()!=="" && sum.push(score?.art4)           

            if (sum.length===3) sum.push(score?.psbart)
            if (sum.length===4)
                {                                    
                    sum=sum.sort((a, b)=>a-b)
                    sum.pop()
                    sum.splice(0,1)
                }
            setScore(prevState=>({...prevState,scoreart:parseFloat(average(sum).toFixed(2))}))
        },[score?.art1,score?.art2,score?.art3,score?.art4,score?.psbart])

    useEffect(()=>
        {
            let result = 0;
            // parseFloat((score?.scoreart+score?.scoreisp+score?.scoredifficulty)-(score?.height?score.height:0+score?.suit+score?.zastup+score?.sbavkitl+score?.other)).toFixed(2)
             
             if (score?.scoreisp!==undefined)result+=score?.scoreisp;
             if (score?.scoreart!==undefined) result+=score?.scoreart;
             if (score?.scoredifficulty!==undefined)result+=score?.scoredifficulty;
             if (score?.height!==undefined)result-=score?.height;
             if (score?.suit!==undefined)result-=score?.suit;
             if (score?.zastup!==undefined)result-=score?.zastup;
             if (score?.sbavkitl!==undefined)result-=score?.sbavkitl;
             if (score?.other!==undefined)result-=score?.other;
            
             console.log(score)
            
            setScore(prevState=>({...prevState,score:result.toFixed(2)}))
        },[score?.scoreart,score?.scoreisp,score?.scoredifficulty,score?.height,score?.suit,score?.zastup,score?.sbavkitl,score?.other])

    useEffect(()=>
        {
            score?.score!==null && score?.score!==undefined &&
            setValues(prevState=>({...prevState,score:score?.score,score_json:JSON.stringify(score)})) 
        },[score])

        useEffect(()=>
            {
              if(contextValue.championat.championat_style_name==="КПН")
                {let sum=[]
                score?.kpn1?.toString().trim()!=="" && sum.push(score?.kpn1)
                score?.kpn2?.toString().trim()!=="" && sum.push(score?.kpn2)
                score?.kpn3?.toString().trim()!=="" && sum.push(score?.kpn3)
                score?.kpn4?.toString().trim()!=="" && sum.push(score?.kpn4)
                score?.kpn5?.toString().trim()!=="" && sum.push(score?.kpn5)
                score?.kpn6?.toString().trim()!=="" && sum.push(score?.kpn6)
                score?.kpn7?.toString().trim()!=="" && sum.push(score?.kpn7)           
                
                setScore(prevState=>({...prevState,score:average(sum)})) }
            },[score?.kpn1,score?.kpn2,score?.kpn3,score?.kpn4,score?.kpn5,score?.kpn6,score?.kpn7])

    function checkInput()
    {
        if (values.score==='')
        {
            return false;
        }
        return true;
    }

    function handleSubmit()
    {            
            if(!open && checkInput())
            {
                setOpen(true)
                axios.post("/api/results/add",values)
                    .then(res=>{
                        if(res.data.Status==="Результат сохранен")
                        { 
                            console.log(props.orders)
                            props.orders.championat_result_id=res.data.championat_result_id
                            props.orders.score=values.score;
                            props.orders.score_json=values.score_json
                            props.setOrders(props.orders)                           
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg("Результат сохранен")
                            contextValue.setOpenAlert(true)
                            props.setActive(false)
                        setOpen(false)            
                        }
                        else alert(res.data.Error);
                    })
                    .catch(err =>alert(err));
            }
            else return false;           
                
    }
    const [open, setOpen] = useState(false);
                return ( 
                    <Box
                            sx={{
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center'               
                            }}
                            >
                               <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
      <CircularProgress color="inherit" />
      </Backdrop>

            <Typography
                    variant="body2"
                    sx={{ my:1,
                    display: { xs: 'flex', md: 'flex' },
                    fontFamily: 'Poppins',
                    textAlign:'center',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                ><p>{`${contextValue.championat.championat_style_name==="Парно-групповое"?props.orders?.sostav_type_name+": ":"" }${props.orders?.FN?.replace('\n',', ')}`}</p>
                </Typography>
     
        {contextValue.championat.championat_style_name==="Парно-групповое"&&
        <><Typography
                    variant="body2"
                    sx={{ my:1,
                    display: { xs: 'flex', md: 'flex' },
                    fontFamily: 'Poppins',
                    textAlign:'center',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                ><p>{`${props.orders?.composition_type_name} упражнение-${props.orders?.group_category_name}-${props.orders?.razryad_short_name}`}</p>
                </Typography>
                <Paper sx={{ width: '100%' }}>
      
      <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" size='small' sx={{
      '& .MuiTableCell-sizeSmall': {
        padding: '3px',
      },
    }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={5}  sx={{ border: 1 }}>
                Техника исполнения
              </TableCell>
              <TableCell align="center" colSpan={3}  sx={{ border: 1 }}>
                Сбавки СТ
              </TableCell>
              <TableCell align="center" colSpan={2}  sx={{ border: 1 }}>
                Средняя оценка
              </TableCell>
              <TableCell align="center" rowSpan={2}  sx={{ border: 1 }}>
                Оценка исполнения
              </TableCell>
              </TableRow>
              <TableRow>
              <TableCell align="center"  sx={{ border: 1 }}>
                ПСБ
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИ-1
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИ-2
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИ-3
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИ-4
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                Ошибки времени СУ
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                Спец.треб.
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                Запрещ.Эл.
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИ
              </TableCell>
              <TableCell align="center"  sx={{ border: 1 }}>
                ТИх2
              </TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.psbti} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, psbti:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                value={score?.ti1} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, ti1:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                value={score?.ti2} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, ti2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
</TableCell>
<TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                value={score?.ti3} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, ti3:e.target.value!==''?parseFloat(e.target.value):""})}}/>
            </TableCell>
            <TableCell  sx={{ border: 1 }}>
            <TextField id="outlined-basic" 
            className={classes.input}
                value={score?.ti4} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, ti4:e.target.value!==''?parseFloat(e.target.value):""})}}/>
            </TableCell>

            <TableCell  sx={{ border: 1 }}>
            <TextField id="outlined-basic" 
            className={classes.input}
                value={score?.ertime} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, ertime:e.target.value!==''?parseFloat(e.target.value):""})}}/>
            </TableCell>

            <TableCell  sx={{ border: 1 }}>
            <TextField id="outlined-basic" 
            className={classes.input}
                value={score?.spec} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, spec:e.target.value!==''?parseFloat(e.target.value):""})}}/>
            </TableCell>

            <TableCell  sx={{ border: 1 }}>
            <TextField id="outlined-basic" 
            className={classes.input}
                value={score?.warn} 
                step={0.01} 
                type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                      setScore({...score, warn:e.target.value!==''?parseFloat(e.target.value):""})}}/>
            </TableCell>

            <TableCell align='center' sx={{ border: 1 }}>
                {score?.avgti}           
            </TableCell>

            <TableCell align='center' sx={{ border: 1 }}>
                {score?.avgtix2} 
            </TableCell>


                <TableCell align='center' sx={{ border: 1 }}>
                {score?.scoreisp} 
                </TableCell>
                </TableRow>
                {!props.orders?.razryad_short_name.includes("юн")&&
                <>
                <TableRow>
                <TableCell colSpan={5} align="center"  sx={{ border: 1 }}>
                    Артистизм
                </TableCell>
                <TableCell colSpan={5} align="center"  sx={{ border: 1 }}>
                    Сбавки ПСБ
                </TableCell>
                <TableCell rowSpan={2} align="center"  sx={{ border: 1 }}>
                    Оценка артистизма
                </TableCell>
                </TableRow>

                <TableRow>
                <TableCell align="center"  sx={{ border: 1 }}>
                ПСБ
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                А-1
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                А-2
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                А-3
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                А-4
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                За рост
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                За костюм
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                Заступ
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                Сбавки ТЛ
                </TableCell>
                <TableCell align="center"  sx={{ border: 1 }}>
                Другие
                </TableCell>                    
                </TableRow>
                

                <TableRow>
                <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.psbart} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, psbart:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>    
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.art1} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, art1:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.art2} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, art2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.art3} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, art3:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.art4} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, art4:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.height} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, height:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.suit} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, suit:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.zastup} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, zastup:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.sbavkitl} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, sbavkitl:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.other} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, other:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>

                    <TableCell align='center'  sx={{ border: 1 }}>
                    {score?.scoreart}
                    </TableCell>

                </TableRow></>}

                <TableRow>
                <TableCell colSpan={2}  sx={{ border: 1 }}>
                 Трудность
                    </TableCell>
                <TableCell  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.difficulty} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, difficulty:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell colSpan={2}  sx={{ border: 1 }}>
                    Оценка трудности
                    </TableCell>
                    <TableCell colSpan={2}  sx={{ border: 1 }}>
                    <TextField id="outlined-basic" 
                    className={classes.input}
                        value={score?.scoredifficulty} 
                        step={0.01} 
                        type='number' 
                        variant="outlined" 
                        InputLabelProps={{
                            inputProps: {min: 0, max: 10}
                        }}
                        onChange={e=>{
                            var value = parseFloat(e.target.value);
                            if (value > 10) value = 10;
                            if (value < 0) value = 0;                    
                            setScore({...score, scoredifficulty:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                    </TableCell>
                    <TableCell colSpan={3}  sx={{ border: 1 }}> 
                    Общая оценка упражнения
                    </TableCell>
                    <TableCell align='center'  sx={{ border: 1 }}>
                    {score?.score} 
                    </TableCell>
                </TableRow>
                <TableRow>
                </TableRow>
                </TableBody>
                </Table>
                </TableContainer>
                </Paper>
                </>
                }
    {contextValue.championat.championat_style_name==="КПН"&&
        <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size='small' sx={{
      '& .MuiTableCell-sizeSmall': {
        padding: '3px',
      },
    }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №1
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №2
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №3
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №4
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №5
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №6
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                №7
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Среднее значение
              </TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn1} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn1:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn3} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn3:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn4} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn4:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn5} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn5:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn6} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn6:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.kpn7} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, kpn7:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell sx={{ border: 1 }}>
                    {score?.score}
                </TableCell>
                </TableRow>
                </TableBody>
                </Table>
                </TableContainer>
                </Paper>
                }
                {contextValue.championat.championat_style_name==="Батут"&&
        <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size='small' sx={{
      '& .MuiTableCell-sizeSmall': {
        padding: '3px',
      },
    }}>
          <TableHead>
           
            <TableRow>
              <TableCell align="center" colSpan={1}  sx={{ border: 1, padding:0, margin:0}}>
                Э1
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э2
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э3
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э4
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э5
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э6
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э7
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э8
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э9
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Э10
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                П
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Тех.
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Тр.
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Пр.
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Вр.
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Оценка
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                Итого
              </TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e1} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e1:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e3} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e3:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e4} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e4:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e5} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e5:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e6} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e6:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e7} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e7:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e8} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e8:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e9} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e9:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e10} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e10:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.p} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, p:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.tex} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, tex:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>

                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.tr} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, tr:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.pr} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, pr:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.vr} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, vr:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.s} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, s:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell rowSpan={2} sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.score} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                     setScore({...score, score:e.target.value!==''?parseFloat(e.target.value):""})
                    }}/>
                </TableCell>
               
                </TableRow>
                <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e1_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e1_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e2_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e2_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e3_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e3_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e4_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e4_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e5_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e5_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e6_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e6_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e7_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e7_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e8_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e8_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e9_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e9_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e10_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e10_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.p_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, p_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.tex_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, tex_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>

                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.tr_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, tr_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.pr_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, pr_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.vr_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, vr_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.s_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, s_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                </TableRow>
                </TableBody>
                </Table>
                </TableContainer>
                </Paper>
                }
    {contextValue.championat.championat_style_name==="АКД"&&
        <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size='small' sx={{
      '& .MuiTableCell-sizeSmall': {
        padding: '3px',
      },
    }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={11}  sx={{ border: 1 }}>
                I Упражнение
              </TableCell>
              <TableCell align="center" colSpan={1} rowSpan={4}  sx={{ border: 1 }}>
                Оконч. оценка
              </TableCell>
              <TableCell align="center" colSpan={1} rowSpan={4}  sx={{ border: 1 }}>
                ЕВСК
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т1
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т2
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т3
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т4
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т5
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т6
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                E
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                D
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                штраф
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                бонус
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                сумма
              </TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t1} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t1:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t3} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t3:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t4} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t4:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t5} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t5:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t6} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t6:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.d} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, d:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.shtraf} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, shtraf:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.bonus} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, bonus:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.summa} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, summa:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}rowSpan={4}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.score} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, score:e.target.value!==''?parseFloat(e.target.value):""})
                    }
                    }/>
                </TableCell>
                <TableCell  sx={{ border: 1 }} rowSpan={4}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.evsk} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, evsk:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                </TableRow>
                <TableRow>
             
              <TableCell align="center" colSpan={11}  sx={{ border: 1 }}>
                II Упражнение
              </TableCell>
              
            </TableRow>
              <TableRow>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т1
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т2
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т3
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т4
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т5
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                т6
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                E
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                D
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                штраф
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                бонус
              </TableCell>
              <TableCell align="center" colSpan={1}  sx={{ border: 1 }}>
                сумма
              </TableCell>
              </TableRow>
                <TableRow>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t1_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t1_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t2_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t2_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t3_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t3_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t4_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t4_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t5_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t5_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.t6_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, t6_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.e_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, e_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.d_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, d_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.shtraf_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, shtraf_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.bonus_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, bonus_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
                <TableCell  sx={{ border: 1 }}>
                <TextField id="outlined-basic" 
                className={classes.input}
                fullWidth
                value={score?.summa_2} 
                step={0.01} 
                 type='number' 
                variant="outlined" 
                InputLabelProps={{
                    inputProps: {min: 0, max: 10}
                  }}
                onChange={e=>{
                    var value = parseFloat(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;                    
                    setScore({...score, summa_2:e.target.value!==''?parseFloat(e.target.value):""})}}/>
                </TableCell>
               
                </TableRow>
                </TableBody>
                </Table>
                </TableContainer>
                </Paper>
                }
                <Button     
                            disabled={score?.score===null ||  score?.score===undefined || isNaN(score?.score) || open}      
                            variant="contained"
                            key="addsosav"
                            onClick={handleSubmit}
                        >Сохранить </Button>


                        <Button                
                            variant="contained"
                            key="cancel"
                            onClick={()=>props.setActive(false)}
                        >Отмена </Button>
                        <Button                
                            variant="contained"
                            key="dellRes"
                            onClick={()=>deleteResult()}
                        >Удалить результат</Button>
                        <Button                
                            variant="contained"
                            key="dellMember"
                            onClick={()=>deleteitemfromgraph()}
                        >Убрать из графика </Button>
                </Box>);
            
           

            
        
        
      
}
 
export default AddResult;