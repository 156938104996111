import { useEffect, useState ,useContext} from "react";
import {Context} from "./../../context";

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Box, Grid } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SnackbarProvider } from 'notistack';
import { useSnackbar } from "notistack";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddSostavInOrder = (props) => {

    const { enqueueSnackbar } = useSnackbar()

    let newSportsmens=[]
    const contextValue=useContext(Context)
    // const [orders, setOrders] =useState([])
    const [sostavs, setSostavs] =useState([])
    const [selectSostav, setSelectSostav] =useState([])
    const [selectSportsmen, setSelectSportsmen] =useState([])
    const [sportsmens, setSportsmens] =useState([])
    const [treners, setTreners] =useState([])
    const [selectTreners, setSelectTreners] =useState([])
    const [groups, setGroups] =useState([])
    const [selectGroup, setSelectGroup] =useState("")
    const [razryads, setRazryads] =useState([])
    const [selectRazryad, setSelectRazryad] =useState("")
    const [values,setValues] = useState ({
        order_id:null,
        order_member_id:null,
        sostav_id:[],
        sportsmen_id:[],
        razryad_id:'',
        group_category_id:'',
        treners_ids:'',
        order_member_publish:0,
        kpn:2
    })

    const [withoutinorder,setWithoutInOrder]=useState(true)
    const [onlyMy,setOnlyMy]=useState(true)

    useEffect(()=>
      {
        console.log(props.active)
        if(props.active===undefined)
        {
        contextValue.championat.championat_style_name!=="Парно-групповое" && getSportsmens();
        contextValue.championat.championat_style_name==="Парно-групповое" && getSostavs();
        getTreners();
        contextValue.championat.championat_style_name!=="КПН" && getGroupCategory();
        contextValue.championat.championat_style_name!=="КПН" && getRazryadTypes();
        }
      },
      [props.active])

    useEffect(()=>
    {
      if(props.active)
        {
        setOpen(true)
        setValues({...values,order_id:props.order_id})
        if(props.orders!==null)
        {
          LoadINFO()}
        }
        else Clear()
    },[props.active,contextValue.order_member_id]);


    // useEffect(()=>
    // {
    //   Clear()
    //   if(contextValue.order_member_id!==null)
    //   {
    //        if(contextValue.championat.championat_style_name==="Парно-групповое")
    //        axios.post(`/api/orders/get_orders_sostavs/${contextValue.championat_id}`)
    //         .then(res=>{
    //             setOrders(res.data)
    //           })
    //         .catch(err=>console.log(err)) 
    //   else 
    //       axios.post(`/api/orders/get_orders_sportsmens/${contextValue.championat_id}`)
    //       .then(res=>{
    //           setOrders(res.data)
    //         })
    //       .catch(err=>console.log(err))}
    // },[contextValue.order_member_id]);


    useEffect(()=>
    {      
      console.log(values)
    },[values]);

    function Clear()
    {
      setSelectGroup("")
      setSelectRazryad("")
      contextValue.order_member_id===null && setSelectSostav([])
      contextValue.order_member_id===null && setSelectSportsmen([])
      console.log(props)
      setSelectTreners(treners?.filter(x=>x.trener_id===contextValue.trener_id))
      setValues({...values,
      order_id:props.order_id,
      sostav_id:[],
      sportsmen_id:[],
      razryad_id:null,
      group_category_id:'',
      treners_ids:'',
      kpn:2})
    }

    
  
    async function getTreners() {      
      let response = await axios.get(`/api/treners/all_treners`);
      contextValue.trener_id!==''&&setSelectTreners(response.data?.filter(x=>x.trener_id===contextValue.trener_id))
      await setTreners(response.data);
  };
  
  async function getSostavs() {      
    let response = await axios.get(`/api/sostavs/all`);
    await setSostavs(response.data);    
  }

  async function getSportsmens() {      
    let response = await axios.post(`/api/sportsmens/all`);
    console.log(response.data)
    await setSportsmens(response.data);    
  }

  async function getRazryadTypes() {      
    let response = await axios.get(`/api/razryads/get_razryad_types_for_order`);
    let razryads = contextValue.championat.championat_style_name==="Парно-групповое"?response.data.filter(x=>x.individ_only===0):response.data;// Don't need await here
    setOpen(false)
    await setRazryads(razryads);
  }

  
  async function getGroupCategory() {      
    let response = await axios.get(`/api/orders/get_group_category`);
    let groups = response.data;// Don't need await here
    await setGroups(groups);
  }

  useEffect(()=>{
    console.log(selectTreners)
    selectTreners.length>0&&setValues({...values, treners_ids:selectTreners.map(function(v) { return v.trener_id; }).join(',')})
  },[selectTreners])


  async function LoadINFO() { 
    let tr = await axios.get(`/api/treners/all_treners`);     
    let s = await axios.post(`/api/sportsmens/all`);
    let sv = await axios.get(`/api/sostavs/all`);
    console.log(sv.data)
    let om = await axios.get(`/api/orders/order_member/${contextValue.order_member_id}`);
    console.log(om.data)
    let t = await axios.get(`/api/orders/order_member_treners/${contextValue.order_member_id}`);
    let r = await axios.get(`/api/razryads/get_razryad_types_for_order`);
    let razryads = contextValue.championat.championat_style_name==="Парно-групповое"?r.data.filter(x=>x.individ_only===0):r.data;// Don't need await here
    let g = await axios.get(`/api/orders/get_group_category`);
    {contextValue.championat.championat_style_name!=="Парно-групповое"&&await setSelectSportsmen([s.data[s.data.findIndex(x=>x.sportsmen_id===om.data[0].ID_KPN)]])}
    {contextValue.championat.championat_style_name!=="КПН"&&await setSelectGroup(g.data[g.data.findIndex(x=>x.group_category_id===om.data[0].group_category_id)])}
    {contextValue.championat.championat_style_name!=="КПН"&&await setSelectRazryad(razryads[razryads.findIndex(x=>x.razryad_id===om.data[0].razryad_id)])} 

    {contextValue.championat.championat_style_name==="Парно-групповое"&&await setSelectSostav([sv.data[sv.data.findIndex(x=>x.sostav_id===om.data[0].sostav_id)]])} 
    await setValues({...values,
              order_id:om.data[0].order_id,
              order_member_id:om.data[0].order_member_id,
              sostav_id:om.data[0].sostav_id===null?[]:JSON.stringify([sv.data[sv.data.findIndex(x=>x.sostav_id===om.data[0].sostav_id)]]),
              sportsmen_id:om.data[0].ID_KPN===null?[]:JSON.stringify([s.data[s.data.findIndex(x=>x.sportsmen_id===om.data[0].ID_KPN)]]),
              razryad_id:om.data[0].razryad_id,
              group_category_id:om.data[0].group_category_id,
              treners_ids:[],
              // t.data.map(function(v) { return v.trener_id; }).join(','),
              order_member_publish:1,
              kpn:om.data[0].kpn});
    await setSportsmens(s.data); 
    await setSostavs(sv.data); 
    await setTreners(tr.data); 
    await setSelectTreners(t.data)
    await setRazryads(razryads)
    await setGroups(g.data);
    setOpen(false)
  }


  function check()
  {
    if(contextValue.championat.championat_style_name!=="Парно-групповое" && values.sportsmen_id.length<1)
    {
      contextValue.setTypeMsg("error")
      contextValue.setMsg(`Спортсмен не выбран`)
      contextValue.setOpenAlert(true)  
      return false;
    }
    if(contextValue.championat.championat_style_name==="Парно-групповое"  && values.sostav_id.length<1)
    {
      contextValue.setTypeMsg("error")
      contextValue.setMsg(`Состав не выбран`)
      contextValue.setOpenAlert(true)  
      return false;
    }
    if(contextValue.championat.championat_style_name!=="КПН" && values.razryad_id==="")
    {
      contextValue.setTypeMsg("error")
      contextValue.setMsg(`Разряд не выбран`)
      contextValue.setOpenAlert(true)  
      return false;
    }
    if(contextValue.championat.championat_style_name==="Парно-групповое" && values.group_category_id==='')
    {
      contextValue.setTypeMsg("error")
      contextValue.setMsg(`Возраст не выбран`)
      contextValue.setOpenAlert(true)  
      return false;
    }
    if(values.treners_ids==='')
    {
      contextValue.setTypeMsg("error")
      contextValue.setMsg(`Тренер не выбран`)
      contextValue.setOpenAlert(true)  
      return false;
    }

    if(contextValue.championat.championat_style_name==="Парно-групповое" )
      {
        let sostavs = JSON.parse(values.sostav_id)
        for(let i=0;i<sostavs.length;i++)
          {
            
            if((props.order!==undefined?props.order:props.orders).filter((o)=>
              o.sostav_id===sostavs[i].sostav_id && o.razryad_id===values.razryad_id && o.group_category_id===values.group_category_id && o.order_member_id!==values.order_member_id).length>0)
              {
                enqueueSnackbar(`Комбинация ${sostavs[i].FIO},${razryads[razryads.findIndex(x=>x.razryad_id===values.razryad_id)].razryad_short_name} разряд, возрастная группа ${groups[groups.findIndex(x=>x.group_category_id===values.group_category_id)].group_category_name} уже присутствует в заявке`, {
                variant: "error"})
              }
              else newSportsmens.push(sostavs[i])
          }
          if(newSportsmens.length>0) {
            setSelectSostav(newSportsmens)
            setValues({...values,sostav_id:JSON.stringify(newSportsmens)})
          }
          else 
          {
            contextValue.setTypeMsg("error")
            contextValue.setMsg(`Участник(и) уже находятся в заявке`)
            contextValue.setOpenAlert(true)
            return false
          }
      }
    else if(contextValue.championat.championat_style_name==="КПН" )
    {
        let sportsmens = JSON.parse(values.sportsmen_id);
        for(let i=0;i<sportsmens.length;i++)
          {
            console.log(props.order)
            console.log(props.orders)
            if((props.order!==undefined?props.order:props.orders).filter((o)=>
              o.sportsmen_id===sportsmens[i].sportsmen_id && o.kpn===values.kpn && o.order_member_id!==values.order_member_id).length>0 )
              {
                enqueueSnackbar(`Спортсмен ${sportsmens[i].FIO}, уровень подготовки уже присутствует в заявке`, {
                variant: "error"})
              }
              else newSportsmens.push(sportsmens[i])
          }
          if(newSportsmens.length>0) {
            setSelectSportsmen(newSportsmens)
            setValues({...values,sportsmen_id:JSON.stringify(newSportsmens)})
          }
          else 
          {
            contextValue.setTypeMsg("error")
            contextValue.setMsg(`Участник(и) уже находятся в заявке`)
            contextValue.setOpenAlert(true)
            return false
          }
      }
      else {
                let sportsmens = JSON.parse(values.sportsmen_id);
                for(let i=0;i<sportsmens.length;i++)
                  {
                    
                    if((props.order!==undefined?props.order:props.orders).filter((o)=>
                      o.sportsmen_id===sportsmens[i].sportsmen_id  && o.razryad_id===values.razryad_id && o.group_category_id===values.group_category_id && o.order_member_id!==values.order_member_id).length>0 )
                      {
                        enqueueSnackbar(`Спортсмен ${sportsmens[i].FIO}, ${razryads[razryads.findIndex(x=>x.razryad_id===values.razryad_id)].razryad_short_name} разряд, возрастная группа ${groups[groups.findIndex(x=>x.group_category_id===values.group_category_id)].group_category_name} уровень подготовки уже присутствует в заявке`, {
                        variant: "error"})
                      }
                      else newSportsmens.push(sportsmens[i])
                  }
                  if(newSportsmens.length>0) {
                    setSelectSportsmen(newSportsmens)
                    setValues({...values,sportsmen_id:JSON.stringify(newSportsmens)})
                  }
                  else 
                  {
                    contextValue.setTypeMsg("error")
                    contextValue.setMsg(`Участник(и) уже находятся в заявке`)
                    contextValue.setOpenAlert(true)
                    return false
                  }
              }
    return true;   

  }
  function handleSubmit()
  {
          if(check())
          {
              let send=
              {
                order_id:values.order_id,
                order_member_id:values.order_member_id,
                sostav_id:selectSostav.length>0?JSON.stringify(newSportsmens):[],
                sportsmen_id:selectSportsmen.length>0?JSON.stringify(newSportsmens):[],
                razryad_id:values.razryad_id,
                group_category_id:values.group_category_id,
                treners_ids:values.treners_ids,
                order_member_publish:values.order_member_publish,
                kpn:values.kpn
              }
              console.log(send)
              axios.post("/api/orders/order_members/add",send)
                  .then(res=>{
                      if(res.data.Status==="Участник добавлен в заявку")
                      {                            
                          contextValue.setTypeMsg("success")
                          contextValue.setMsg(contextValue.order_member_id!==null?"Изменения сохранены":"Участник добавлен в заявку")
                          contextValue.setOpenAlert(true)
                          setValues({...values,sportsmen_id:[],sostav_id:[]})
                          setSelectSostav([])
                          setSelectSportsmen([])
                          contextValue.setOrderMemberID(null)
                          contextValue.order_member_id!==null && props.setActive(false)
                      }
                      else alert(res.data.Error);
                  })
                  .catch(err =>alert(err));
          }                   
  }

  const [FiltredSportsmens,setFiltredSportsmens]=useState([])
  const [FiltredSostavs,setFiltredSostavs]=useState([])

  useEffect(()=>{
    let filtred=sportsmens
    console.log(contextValue)
    if(contextValue.order_member_id===null)
    {     
      filtred=filtred?.filter((x)=>x.school_id===contextValue.school_id && x.sportsmen_archive===0)
      filtred=withoutinorder?filtred?.filter(el_A => !props.order.some(el_B => el_A.sportsmen_id === el_B.sportsmen_id)):filtred
      filtred=onlyMy?filtred?.filter((x)=>x.trener_id===contextValue.trener_id):filtred
    }
    console.log(filtred)
    setFiltredSportsmens(filtred)},[props.active,sportsmens,contextValue.order_member_id,withoutinorder,onlyMy])

    useEffect(()=>{
      let filtred=sostavs
      // console.log(contextValue)
      if(contextValue.order_member_id===null)
      {     
        filtred=filtred?.filter((x)=>x.school_id===contextValue.school_id && x.sostav_archive===0)
        filtred=withoutinorder?filtred?.filter(el_A => !props.order.some(el_B => el_A.sostav_id === el_B.sostav_id)):filtred
        // filtred=onlyMy?filtred?.filter((x)=>x.trener_id===contextValue.trener_id):filtred
      }
      // console.log(filtred)
      setFiltredSostavs(filtred)},[sostavs,contextValue.order_member_id,withoutinorder,onlyMy])



  useEffect(()=>{props.active===false && contextValue.setOrderMemberID(null)},[props.active])

  function FiltredTreners()
  {
    let newTreners=props.active===undefined?
    treners?.filter((x)=>x.school_id===contextValue.school_id)
    :
    treners?.filter((x)=>
    x.school_id===
    (contextValue.championat.championat_style_name==="Парно-групповое" 
    ?
    selectSostav[0]?.school_id
    :
    selectSportsmen[0]?.school_id))
    return newTreners;
  }

  const [open, setOpen] = useState(false);   
    return (
      <SnackbarProvider maxSnack={10}>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
        <Box sx={{ padding:3, flexGrow: 1 }}>
        <Grid container spacing={1} columns={{ xs: 12, md: 18 }}>
          {contextValue.order_member_id===null && <Grid item xs={4} md={4} display="flex" justifyContent="start" alignItems="center" size="grow">
                <FormControlLabel sx={{ p: 1 }}
                  control={<Checkbox checked={withoutinorder} onChange={(event,x)=>setWithoutInOrder(x)}/>}
                  label="Не отображать добавленных"
                />
                </Grid>}
                {contextValue.order_member_id===null && contextValue.championat.championat_style_name!=="Парно-групповое" && contextValue.userType===1&&<Grid item xs={4} md={4} display="flex" justifyContent="start" alignItems="center" size="grow">
                <FormControlLabel sx={{ p: 1 }}
                  control={<Checkbox checked={onlyMy} onChange={(event,x)=>setOnlyMy(x)}/>}
                  label="Только мои"
                />
                </Grid>}
             {contextValue.championat.championat_style_name==="Парно-групповое"  && <Grid item xs={12} md={18}>
             <Autocomplete
                    id="sostavs"
                    disablePortal
                    multiple
                    disableCloseOnSelect={contextValue.order_member_id===null}
                    disabled={props.active!==undefined && contextValue.order_member_id!==null}
                    value={selectSostav}     
                    options={FiltredSostavs}                    
                    renderInput={(params) => <TextField {...params}  label="Состав"/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option?.FIO!==undefined?option?.FIO?.replaceAll("..","."):""}`}
                      </li>
                    )}
                    onChange={(event, value) =>
                      { 
                        setSelectSostav(value);
                        setValues({...values, sostav_id:value!==null?JSON.stringify(value):""})
                      }}
                    groupBy={(option) => option.sostav_type_name}                            
                    getOptionLabel={(option) =>`${option?.FIO!==undefined?option?.FIO?.replaceAll("\n",", ")?.replaceAll("..","."):""}`}
                    />
             </Grid>}

             {contextValue.championat.championat_style_name!=="Парно-групповое" &&<Grid item xs={12} md={18}>
             
             <Autocomplete
                    id="sportsmens"
                    disablePortal
                    multiple
                    disableCloseOnSelect={contextValue.order_member_id===null}
                    disabled={props.active!==undefined && contextValue.order_member_id!==null}
                    // onClick={props.active===undefined&&contextValue.setOrderMemberID(null)}
                    value={selectSportsmen}                    
                    options={FiltredSportsmens}     
                      getOptionLabel={(option) =>`${option?.FIO!==undefined?option?.FIO?.replaceAll("..","."):""}`}  
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {`${option?.FIO!==undefined?option?.FIO?.replaceAll("..","."):""}`}
                        </li>
                      )}
                     renderInput={(params) => <TextField {...params}  label="Спортсмен"/>}
                    onChange={(event, value) =>
                      { 
                        setSelectSportsmen(value);
                        setValues({...values, sportsmen_id:value!==null?JSON.stringify(value):''})
                      }}                          
                    
                    />
             </Grid>}

             {contextValue.championat.championat_style_name==="КПН" &&<Grid item xs={12} md={3}>
                <TextField
                    id="outlined-number"
                    label="Уровень подготовки"
                    type="number"
                    fullWidth
                    value={values.kpn}
                    InputLabelProps={{
                      inputProps: {min: 1, max: 3}
                    }}
                    onChange={e=>{
                      var value = parseInt(e.target.value, 10);
                      if (value > 3) value = 3;
                      if (value < 1) value = 1;                      
                      setValues({...values, kpn:value})}}
                />
             </Grid>}

             {contextValue.championat.championat_style_name==="Парно-групповое"  && <Grid item xs={12} md={5}>
            <Autocomplete
                    id="groups"
                    disablePortal
                    value={selectGroup} 
                    options={groups}                            
                    renderInput={(params) => <TextField {...params} label="Категория"/>}
                    onChange={(event, value) => {
                      setSelectGroup(value)
                      setValues({...values, group_category_id:value!==null?value.group_category_id:""})}}
                      getOptionLabel={(option) =>`${option?.group_category_name!==undefined?option?.group_category_name:""}`}
                    
                    /> 
                </Grid>}
                
                {contextValue.championat.championat_style_name!=="КПН"  && <Grid item xs={12} md={5}><Autocomplete
                    id="razryads"
                    disablePortal
                    value={selectRazryad} 
                    options={razryads}                            
                    getOptionLabel={(option) =>`${option?.razryad_short_name!==undefined?option?.razryad_short_name:""}`}
                    onChange={(event, value) => {
                      setSelectRazryad(value)
                      setValues({...values, razryad_id:value!==null?value.razryad_id:""})}}
                    renderInput={(params) => <TextField {...params} label="Разряд"/>}
                    /></Grid>}
                
            <Grid item xs={12} md={8}>
            <Autocomplete
                    id="trener"
                    disablePortal
                    disableCloseOnSelect
                    value={selectTreners} 
                    multiple
                    sx={{display:contextValue.userType!==2?'flex':'none'}}
                    options={FiltredTreners()}                            
                    getOptionLabel={(option) =>`${option?.FIO}`}
                    onChange={(event, value) => {
                      setSelectTreners(value)
                      // setValues({...values, treners_ids:value.map(function(v) { return v.trener_id; }).join(',')})
                      }
                      }
                      renderOption={(props, option) => {
                        const isSelected = selectTreners.some(trener => trener.trener_id === option.trener_id);
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={isSelected}
                            />
                            {option?.FIO !== undefined ? option.FIO.replaceAll("..", ".") : ""}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Тренеры" />}
                    />
                </Grid>
                <Grid item xs={12} md={18} alignContent={"center"}>
                <Grid container spacing={2} alignItems={"center"} alignContent={"center"}>
                <Grid item xs={12} md={4} alignContent={"center"}>
                {contextValue.order_member_id===null?               
                               <Button 
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>{handleSubmit()}}
                                  fullWidth                                  
                                >Добавить</Button>
                                :
                                <Button 
                                  variant="contained"
                                  startIcon={<SaveOutlinedIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>{handleSubmit()}}
                                  fullWidth                                  
                                >Сохранить</Button>}
                </Grid>
                <Grid item xs={12} md={4} alignContent={"center"}>
                <Button 
                                  variant="contained"
                                  startIcon={<CloseIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>Clear()}
                                  fullWidth                                  
                                >Очистить</Button> 
                </Grid>
                
                {contextValue.order_member_id &&
                <Grid item xs={12} md={4} alignContent={"center"}>
                <Button 
                                  variant="contained"
                                  startIcon={<CloseIcon />} 
                                  size="middle"
                                  key="addSostavInOrder"
                                  onClick={()=>
                                    {contextValue.setOrderMemberID(null);
                                    
                                    props.setActive(false)}}
                                  fullWidth                                  
                                >Отменить</Button> 
                </Grid>}
                </Grid>
                </Grid>
        </Grid>
        </Box>
        </SnackbarProvider>      
        
        
      );
      
}
 
export default AddSostavInOrder;