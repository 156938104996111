import { useEffect, useState ,useContext} from "react";
import "./../styles/main.css"
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Slide} from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Tooltip from '@mui/material/Tooltip'; 
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { List, ListItem, ListItemIcon } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

import { createTheme } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import Draggable from 'react-draggable';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';




import AddResult from "./AddResult";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper fullWidth {...props} />
    </Draggable>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  minWidth:"400px",
  p:"0px",
  textAlign: 'center',
  transition: 'background-color 0.3s ease', // Для плавного перехода цвета
  '&:hover': {
    backgroundColor: '#ADD8E6'}// Светлоголубой цвет
}));

const theme = createTheme({
  shadows: ["none"]
});

theme.typography = {
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Input = styled(MuiInput)`
  width: 42px;
`;

const PrintButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(12),
    right: theme.spacing(2)
}));
const SettingButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(12),
    right: theme.spacing(12)
}));
const BackButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(12),
    right: theme.spacing(22)
}));

const Back = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ChampionatGraph = (props) => {
    const contextValue=useContext(Context)    
    const [showResult,setShowResult]=useState(false)
    const [graphs, setGraphs] =useState([])
    const [order, setOrder] =useState([])
    const [graph, setGraph] =useState([])
    let headerList=[];
    const [load, setLoad]=useState(false)
    const potocHeader=[];
    const headers_check=[];
    const [brigade,changeBrigade]=useState(false)
    const componentRef = useRef();
    

    
  const [select,setSelect]=useState(null)
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


    const [parads, setParads] =useState(
      {
        check:false,
        nadrad_parad_uoshi:false,
        nadrad_parad_uoshi_time:15,
        nadrad_parad_sports:false,
        nadrad_parad_sports_time:15,
        nadrad_parad_masters:false,
        nadrad_parad_masters_time:15,
      }
    )

    // useEffect(()=>{console.log(parads)},[parads])  
    const [OpenParad, setOpenParad] =useState(
      {
        check:false,
        showOpenTime:null,
        showEndTime:null,
        openParad_start_time:'',
        openParad_end_time:'',
        openParad_time:30
      }
    )

    // useEffect(()=>{console.log(OpenParad)},[OpenParad])  

    const [sort, setSort] = useState({
      check:false,
      razryad_sort:"По возрастанию",
      score_sort:"По возрастанию",
      age_sort:"По возрастанию",
      gender_sort:"Сначала мальчики",
      level_sort:"По возрастанию",
      group_sort:"По возрастанию",
    })


    // useEffect(()=>{console.log(sort)},[sort])  
    const [grouping, setGrouping] =useState(
      {
        grouping_razryad:0,
        grouping_score:0,
        grouping_gender:0,
        grouping_age:0,
        grouping_level:0,
        grouping_group:0
      }
    )

    // useEffect(()=>{console.log(grouping)},[grouping])  

    const [groups_potoc, setGroupsPotoc] =useState(
      {
        check:false,
        groups_potoc_time:5,
      }
    )

    // useEffect(()=>{console.log(groups_potoc)},[groups_potoc])  
    const [header_potoc, setHeaderPotoc] =useState(
      {
        check:false,
        headers_times:[],
        header_potoc_time:5,
      }
    )
    // useEffect(()=>{},[groups_potoc.check])
    
    // useEffect(()=>{console.log(header_potoc)},[header_potoc])  
    useEffect(()=>{
      if(header_potoc.headers_times.length>0)
      headerList=header_potoc.headers_times},[header_potoc.headers_times])

    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };

    const [NagradParad, setNagradParad] =useState(
      {
        check:false,
        showOpenTime:null,
        showEndTime:null,
        nagradParad_start_time:'',
        nagradParad_end_time:'',
        nagradParad_time:30
      }
    )

    // useEffect(()=>{console.log(NagradParad)},[NagradParad])   
    const [potocs, setPotocs] =useState(
      {
        check:false,
        count:1,
        TimeOut:1,
        maxpotocs:1
      }
    ) 
   
    // useEffect(()=>{console.log(potocs)},[potocs])   
    const [razminka, setRazminka] =useState(
      {
        check:true,
        razminka_time:60
      }
    ) 
    const [timeCompos, setTimeCompos] =useState(
      {
        check:true,
        Time:1
      }
    ) 
     useEffect(()=>{console.log(timeCompos)},[timeCompos])
    const [compositions, setCompositions] =useState(
      {
        check:true,
        unoshi:true,
        sport_one:true,
        sport_two:true,
        masters_one:true,
        masters_two:true,
        masters_three:true
      }
    ) 

    // useEffect(()=>{console.log(compositions)},[compositions])
    const [currentday, setCurrentDay]=useState({
      day:'1',
      start_time:null,
      brigade:'0'})
      // useEffect(()=>{console.log(currentday)},[currentday])

      // const anchor ="right"
      const [state, setState] = useState(false);

    const [potocMarcs, setPotocMarcs] =useState([])
    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

      useEffect(()=>
      {
        if(contextValue.championat_id!==null)
          contextValue.championat.championat_style_name==="Парно-групповое"?
             axios.post(`/api/orders/get_orders_compositions/${contextValue.championat_id}`)
              .then(res=>{
                if(res.data.length>0)
                  {
                  setOrder(res.data) 
                  setPotocs({...potocs,maxpotocs:res.data.length})
                  setPotocs({...potocs,count:res.data.length})
                } 
                  else
                  {
                    contextValue.setTypeMsg("info")
                    contextValue.setMsg(`Пока нет участников для построения стартового протокола`)
                    contextValue.setOpenAlert(true)}               
                })
              .catch(err=>console.log(err)) 
              :
              axios.post(`/api/orders/get_orders_sportsmens/${contextValue.championat_id}`)
              .then(res=>{
                if(res.data.length>0){
                  setOrder(res.data) 
                  setPotocs({...potocs,maxpotocs:res.data.length})
                  setPotocs({...potocs,count:res.data.length})} 
                  else
                  {
                    contextValue.setTypeMsg("info")
                    contextValue.setMsg(`Пока нет участников для построения стартового протокола`)
                    contextValue.setOpenAlert(true)}               
                })
              .catch(err=>console.log(err))   
      },[]);

      useEffect(()=>{        
        
        if(order.length!==0)
          {   
          console.log("Получение параметров графика из базы данных")
          axios.get(`/api/graph/${contextValue.championat_id}`)
          .then(res=>{
                console.log("Данные получены")
                // console.log(res.data)
                setGraphs(res.data===null?[]:res.data)}
            )
      .catch(err=>console.log(err)) 
    }},[order])



    useEffect(()=>
    {
      if(order.length>0)
        {
          setLoad(false)
          loadGraph()
      }
    },[graphs,currentday.day])

    useEffect(()=>
      {
          if(load)
          goToCreateGraph(order)
        
      },[load])


      const goToCreateGraph = async (orders) => {
        console.log("Получение данных завершено")
        const result = await createGraph(orders)// Дожидаемся результата
        setGraph(result); // Устанавливаем результат в состоянии graph
    };

      const createGraph = async (orders) => {
        handleOpen();
        console.log("Построение стартового протокола");
        setPotocs({ ...potocs, maxpotocs: orders.length });
    
        let result;
        if (contextValue.championat.championat_style_name === "Парно-групповое") {
            const days = await setDays(orders); 
            const score = await setSumScore(days); 
            const filtred = score.filter(x => x.day === Number(currentday.day));
            const sparads = await sortIfParad(filtred);
            result = await setTime(sparads); 
            handleClose();
        } else {
            // result = await setTime(await setSumScore(sortIfParad(await setDays(orders)))); 
            const days = await setDays(orders); 
            const score = await setSumScore(days); 
            // const filtred = score.filter(x => x.day === Number(currentday.day));
            const sparads = await sortIfParad(score);
            result = await setTime(sparads);
            handleClose();
        }
        // console.log(result);
        return result; // Убедитесь, что это то, что вы хотите вернуть
    };

    function loadGraph()
    {
      handleOpen()
      console.log(`Загрузка стартового протокола ${currentday.day}`)
      let g=null
      if(graphs?.length>0){g=graphs[graphs.findIndex(x=>x.graph_day===Number(currentday.day))];
      console.log(graphs)
      console.log(graphs[graphs.findIndex(x=>x.graph_day===Number(currentday.day))])
      console.log(g)}
      if(g===null || g===undefined)
      {
        let gj=
        {
          graph_id:null,
          championat_id:contextValue.championat_id,
          graph_day:Number(currentday.day),
          start_time:new Date(),
          sort:true,
          razryad_sort:"По возрастанию",  
          grouping_razryad:contextValue.championat.championat_style_name !== "КПН"?true:false,
          group_sort:"По возрастанию",
          grouping_group:contextValue.championat.championat_style_name !== "КПН"?true:false,
          score_sort:"По возрастанию",
          grouping_score:false,
          gender_sort:"Сначала мальчики",
          grouping_gender:contextValue.championat.championat_style_name === "КПН"?true:false,
          age_sort:"По возрастанию",
          grouping_age:contextValue.championat.championat_style_name === "КПН"?true:false,
          level_sort:"По возрастанию",
          grouping_level:contextValue.championat.championat_style_name === "КПН"?true:false,
          open_parad:false,
          open_parad_time_start:new Date(),
          open_parad_time:1,
          nagrad_parad:false,
          nagrad_parad_time_start:new Date(),
          nagrad_parad_time:1,
          groups_potoc:false,
          groups_potoc_time:1,
          potocs:false,
          potocs_count:1,
          potocs_timeout:1,
          compositions:contextValue.championat.championat_style_name === "Парно-групповое"?true:false,
          compositions_unoshi:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_unoshi && x.graph_day!==Number(currentday.day)).length>0:false,
          compositions_sport_one:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_sport_one && x.graph_day!==Number(currentday.day)).length>0:false,
          compositions_sport_two:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_sport_two && x.graph_day!==Number(currentday.day)).length>0:false,
          compositions_masters_one:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_one && x.graph_day!==Number(currentday.day)).length>0:false,
          compositions_masters_two:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_two && x.graph_day!==Number(currentday.day)).length>0:false,
          compositions_masters_three:contextValue.championat.championat_style_name === "Парно-групповое"?!graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_three && x.graph_day!==Number(currentday.day)).length>0:false,
          brigade:false,
          parads:false,
          nadrad_parad_uoshi:false,
          nadrad_parad_uoshi_time:15,
          nadrad_parad_sports:false,
          nadrad_parad_sports_time:15,
          nadrad_parad_masters:false,
          nadrad_parad_masters_time:15,
          check:false,
          headers:'[{}]',
          razminka:true,
          razminka_time:60,      
          header_potoc_times:5,
          header_potoc:false,
          timeCompos:true,
          Time:1
        }
        g={
          championat_id:contextValue.championat_id,
          graph_day:Number(currentday.day),
          graph_json:JSON.stringify(gj)}
        let new_graphs=graphs
        new_graphs.push(g)
        // console.log(g)
        setGraphs(new_graphs)
      }

      
        let graph=JSON.parse(g.graph_json);
         delay(10).then(() =>    changeBrigade(graph.brigade))
         delay(10).then(() =>    setParads({...parads,
          check:Boolean(graph.parads),
          nadrad_parad_uoshi:graph.nadrad_parad_uoshi,
          nadrad_parad_uoshi_time:graph.nadrad_parad_uoshi_time,
          nadrad_parad_sports:graph.nadrad_parad_sports,
          nadrad_parad_sports_time:graph.nadrad_parad_sports_time,
          nadrad_parad_masters:graph.nadrad_parad_masters,
          nadrad_parad_masters_time:graph.nadrad_parad_masters_time
        }))
         delay(10).then(() =>    setSort({...sort,
          check:Boolean(graph.sort),
          razryad_sort:graph.razryad_sort,
          score_sort:graph.score_sort,
          age_sort:graph.age_sort,
          level_sort:graph.level_sort,
          gender_sort:graph.gender_sort,
          group_sort:graph.group_sort}))
           delay(10).then(() =>    setGrouping({...grouping,
            grouping_age:Boolean(graph.grouping_age),
            grouping_gender:Boolean(graph.grouping_gender),
            grouping_level:Boolean(graph.grouping_level),
            grouping_razryad:Boolean(graph.grouping_razryad),
            grouping_score:Boolean(graph.grouping_score),
            grouping_group:Boolean(graph.grouping_group)
          }))
           delay(100).then(() =>    setGroupsPotoc({...groups_potoc,
            check:Boolean(graph.groups_potoc),
            groups_potoc_time:graph.groups_potoc_time
          }))
           delay(10).then(() =>    setRazminka({...razminka,
            check:Boolean(graph.razminka),
            razminka_time:graph.razminka_time
          }))
          delay(10).then(() =>   
            {console.log(Boolean(graph.timeCompos))
             setTimeCompos({...timeCompos,
            check:Boolean(graph.timeCompos),
            Time:graph.Time
          })})
           delay(10).then(() => {
              headerList=JSON.parse(graph.headers)
              setHeaderPotoc({...header_potoc,
              check:Boolean(graph.header_potoc),
              headers_times:JSON.parse(graph.headers),
              header_potoc_time:graph.header_potoc_times
            })})
          
             delay(10).then(() =>   setCompositions({...compositions,
            check:Boolean(graph.compositions),
            unoshi:Boolean(graph.compositions_unoshi),
            sport_one:Boolean(graph.compositions_sport_one),
            sport_two:Boolean(graph.compositions_sport_two),
            masters_one:Boolean(graph.compositions_masters_one),
            masters_two:Boolean(graph.compositions_masters_two),
            masters_three:Boolean(graph.compositions_masters_three)}))

             delay(10).then(() =>{
                let start_parad_time=new Date();
                start_parad_time.setHours(new Date(graph.nagrad_parad_time_start).getHours());
                start_parad_time.setMinutes(new Date(graph.nagrad_parad_time_start).getMinutes());
                start_parad_time.setSeconds(0);
                let end_parad_time=new Date(start_parad_time);
                end_parad_time.setMinutes(end_parad_time.getMinutes()+Number(NagradParad.nagradParad_time));

                setNagradParad({...NagradParad,
                check:Boolean(graph.nagrad_parad),
                showtime:graph.nagrad_parad_time_start!==null?dayjs(graph.nagrad_parad_time_start):null,
                nagradParad_start_time:new Date(graph.nagrad_parad_time_start).getHours()+":"+`${new Date(graph.nagrad_parad_time_start).getMinutes()<10?"0":""}${new Date(graph.nagrad_parad_time_start).getMinutes()}`,
                nagradParad_time:graph.nagrad_parad_time,
                showOpenTime:start_parad_time, 
                showEndTime:end_parad_time,
                nagradParad_end_time:`${end_parad_time.getHours()}:${end_parad_time.getMinutes()<10?"0":""}${end_parad_time.getMinutes()}:${end_parad_time.getSeconds()}`
              })})

               delay(10).then(() => {
                let start_parad_time=new Date();
                start_parad_time.setHours(new Date(graph.open_parad_time_start).getHours());
                start_parad_time.setMinutes(new Date(graph.open_parad_time_start).getMinutes());
                start_parad_time.setSeconds(0);
                let end_parad_time=new Date(start_parad_time);
                end_parad_time.setMinutes(end_parad_time.getMinutes()+Number(OpenParad.openParad_time));
                setOpenParad({...OpenParad,
                check:Boolean(graph.open_parad),
                showtime:graph.open_parad_time_start!==null?dayjs(graph.open_parad_time_start):null,
                openParad_start_time:new Date(graph.open_parad_time_start).getHours()+":"+`${new Date(graph.open_parad_time_start).getMinutes()<10?"0":""}${new Date(graph.open_parad_time_start).getMinutes()}`,
                openParad_time:graph.open_parad_time,
                showOpenTime:start_parad_time, 
                showEndTime:end_parad_time,
                openParad_end_time:`${end_parad_time.getHours()}:${end_parad_time.getMinutes()<10?"0":""}${end_parad_time.getMinutes()}:${end_parad_time.getSeconds()}`

              })})

               delay(10).then(() =>setPotocs(    
                {...potocs,
                  check:Boolean(graph.potocs),
                  count:graph.potocs_count,
                  TimeOut:graph.potocs_timeout
                }))
                delay(10).then(() => setCurrentDay({...currentday,start_time:dayjs(graph.start_time)}))

                 delay(10).then(() =>                
                {
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg(`График на ${graph.graph_day} день загружен`)
                    setLoad(true)
                    contextValue.setOpenAlert(true)
                })
                
    }

    function SaveGraph()
    {
      setLoad(false)
           console.log("Сохранение графика ") 
           const values=
           {
              graph_id:graphs[graphs.findIndex(x=>x.graph_day===Number(currentday.day))]?.graph_id,
              championat_id:contextValue.championat_id,
              graph_day:Number(currentday.day),
              start_time:`${currentday.start_time}`,
              open_parad:OpenParad.check,
              open_parad_time_start:OpenParad.showtime,
              open_parad_time:OpenParad.openParad_time,
              nagrad_parad:NagradParad.check,
              nagrad_parad_time_start:NagradParad.showtime,
              nagrad_parad_time:NagradParad.nagradParad_time,
              groups_potoc:groups_potoc.check,
              groups_potoc_time:groups_potoc.groups_potoc_time,
              potocs:potocs.check,
              potocs_count:potocs.count,
              potocs_timeout:potocs.TimeOut,
              compositions:compositions.check,
              compositions_unoshi:compositions.unoshi,
              compositions_sport_one:compositions.sport_one,
              compositions_sport_two:compositions.sport_two,
              compositions_masters_one:compositions.masters_one,
              compositions_masters_two:compositions.masters_two,
              compositions_masters_three:compositions.masters_three,
              sort:sort.check,
              razryad_sort:sort.razryad_sort,
              group_sort:sort.group_sort,
              score_sort:sort.score_sort,
              gender_sort:sort.gender_sort,
              age_sort:sort.age_sort,
              level_sort:sort.level_sort,
              grouping_group:grouping.grouping_group,
              grouping_score:grouping.grouping_score,
              grouping_razryad:grouping.grouping_razryad,
              grouping_gender:grouping.grouping_gender,
              grouping_age:grouping.grouping_age,
              grouping_level:grouping.grouping_level,
              brigade:brigade,
              parads:parads.check,
              nadrad_parad_uoshi:parads.nadrad_parad_uoshi,
              nadrad_parad_uoshi_time:parads.nadrad_parad_uoshi_time,
              nadrad_parad_sports:parads.nadrad_parad_sports,
              nadrad_parad_sports_time:parads.nadrad_parad_sports_time,
              nadrad_parad_masters:parads.nadrad_parad_masters,
              nadrad_parad_masters_time:parads.nadrad_parad_masters_time,
              header_potoc:header_potoc.check,
              headers:JSON.stringify(header_potoc.headers_times),
              header_potoc_times:header_potoc.header_potoc_time,
              razminka:razminka.check,
              razminka_time:razminka.razminka_time,
              timeCompos:timeCompos.check,
              Time:timeCompos.Time

           }
                axios.post("/api/graph/save",values)
                    .then(res=>{
                        if(res.data.Status==="График сохранен")
                        {      
                          
                          setGraphs(graphs.map((g)=>({...g,
                           graph_id: g.graph_day===Number(currentday.day)?res.data.graph_id:g.graph_id, 
                           graph_json:g.graph_day===Number(currentday.day)?JSON.stringify(values):g.graph_json
                          })))
                            setState(false)                          
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg(`График на ${values.graph_day} день сохранен`)
                            
                            contextValue.setOpenAlert(true)              
                        }
                        else alert(res.data.Error);
                    })
                    .catch(err =>alert(err));  
    }

      function setPotoc(Composition)
      {
          let time=new Date();
          let potoc=Math.ceil((Composition.Number)/potocs.count)
          if(Composition.Number!==1 && (Composition.Number-1)%potocs.count===0){
          time.setHours(Composition.Time.split(':')[0]);
          time.setMinutes(Composition.Time.split(':')[1]);
          time.setMinutes(time.getMinutes()+potocs.TimeOut)
          time.setSeconds(Composition.Time.split(':')[2])
          Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
          }
          Composition.potoc=potoc;
          setMarcs()
        return Composition;
      }

      function setGroupsPotocs(Composition)
      {
          let time=new Date();
          if(!headers_check.includes(Composition.Header))
          {
            headers_check.push(Composition.Header)
            if(Composition.Number!==1)
            {
            time.setHours(Composition.Time.split(':')[0]);
            time.setMinutes(Composition.Time.split(':')[1]);
            time.setMinutes(time.getMinutes()+groups_potoc.groups_potoc_time)
            time.setSeconds(Composition.Time.split(':')[2])
            Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
            }          
        }
        Composition.GroupPotoc=headers_check.length;
        return Composition;
      }
      
      function getSwitch(o)
      {
        switch (contextValue.championat.championat_type_name) {
          case "Школьное":
            return <Box sx={{ display: 'flex', flexDirection: {xs:'row', sm:'column'}, alignItems:{xs:'center',sm:'left'}, }}>{o.hover_card_trener?.split('\n').map((hc, i) => {
              const parts = hc.split('/');
              
              // Проверяем наличие необходимых данных
              if (parts.length < 4) {
                  console.warn(`Недостаточно данных в строке: ${hc}`);
                  return null; // Или можно вернуть что-то другое
              }
          
              const [id, surname, name, patronymic ] = parts; // Добавляем imageUrl
          
              return (
                  <Tooltip 
                      key={id}
                      placement='right-end' // Используйте уникальный ID или индекс i
                      title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img 
                                  src={`./uploads/users/${id}/avatar_crop.png`} 
                                  alt={`${surname} ${name}`} 
                                  style={{ width: '50px', height: '50px', borderRadius:'50%', marginRight: '10px' }} // Размер изображения
                              />
                              <div>
                                  <Typography variant="body2">{surname} {name}</Typography>
                                  <Typography variant="body2">{patronymic}</Typography>
                                  <Typography variant="body2">{o?.school_name}</Typography>
                              </div>
                          </div>
                      }
                      arrow // Добавляет стрелку к подсказке
                  >
                  <Typography 
                          display={{xs:'flex', sm:'none'}}
                          variant="body1" 
                          style={{ fontSize: '10px', cursor: 'pointer', textAlign:'start' }}
                      >
                      {`${i>0?', ':''}`}{`${surname} ${name}`}
                      </Typography>
                          
                      <Typography 
                          display={{xs:'none', sm:'flex'}}
                          variant="body1" 
                          style={{ fontSize: '12px', cursor: 'pointer', textAlign:'start' }}
                      >
                          {`${surname} ${name}`} 
                      </Typography>
                  </Tooltip>
              );
          })}</Box>   
          case "Краевое":
            return JSON.parse(contextValue.championat.championat_adress).data.city   
          case "Межрегиональное":
            return JSON.parse(contextValue.championat.championat_adress).data.federal_district +" Федеральный округ"
          case "Всероссийское":
            return JSON.parse(contextValue.championat.championat_adress).data.region_with_type  
          case "Международное":
            return JSON.parse(contextValue.championat.championat_adress).data.country   
          default:
          return o.school_name
        }
      }
      function setHeadersPotocs(Composition)
      {

          let time=new Date();
          if(!headers_check.includes(Composition.HeaderPotoc))
          {
            headers_check.push(Composition.HeaderPotoc)
            if(Composition.Number!==1)
            {
            time.setHours(Composition.Time.split(':')[0]);
            time.setMinutes(Composition.Time.split(':')[1]);
            time.setMinutes(time.getMinutes()+header_potoc.header_potoc_time)
            time.setSeconds(Composition.Time.split(':')[2])
            Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
            // console.log(Composition.Time)
            }          
        }        
        return Composition;
      }





      function setTimeAfterOpenPorad(Composition)
      {     
            let time_member=new Date();
            time_member.setHours(Number(Composition.Time.split(':')[0]));
            time_member.setMinutes(Number(Composition.Time.split(':')[1]));
            time_member.setSeconds(Number(Composition.Time.split(':')[2]))
            let composition_time_m=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[1]):2
            let composition_time_s=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[2]):0
            time_member.setMinutes(time_member.getMinutes()+composition_time_m)
            time_member.setSeconds(time_member.getSeconds()+composition_time_s)
            
            if(time_member>=OpenParad.showOpenTime)
            {             
                Composition.afterOpenParad=true;   
                if(time_member<OpenParad.showEndTime)
                {                   
                  Composition.Time=OpenParad.openParad_end_time; 
                }
            }
        return Composition
      }

      function setTimeAfterNagradPorad(Composition)
      {     
            let time_member=new Date();
            time_member.setHours(Number(Composition.Time.split(':')[0]));
            time_member.setMinutes(Number(Composition.Time.split(':')[1]));
            time_member.setSeconds(Number(Composition.Time.split(':')[2]))
            let composition_time_m=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[1]):2
            let composition_time_s=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[2]):0
            time_member.setMinutes(time_member.getMinutes()+composition_time_m)
            time_member.setSeconds(time_member.getSeconds()+composition_time_s)
            if(time_member>=NagradParad.showOpenTime)
            {
                Composition.afterNagradParad=true;                
                if(time_member<NagradParad.showEndTime)
                {                                      
                  Composition.Time=NagradParad.nagradParad_end_time; 
                }
            }
        return Composition
      }
      

    async function setDays(all)
    {
      if(contextValue.championat.championat_style_name==="Парно-групповое")
        {
          let c1=[];
          let c2=[];
          let c3=[];
      for(let i=0;i<graphs.length;i++)
      {
        let cur_graph=JSON.parse(graphs[i].graph_json);
        for(let l=0;l<all.length;l++)
        {
              if(all[l].composition_type_id===3)
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_three) {all[l].day=cur_graph.graph_day;c3.push(all[l])}}
                  else if(all[l].razryad_short_name.includes("юн"))
                  if(cur_graph.compositions_unoshi){all[l].day=cur_graph.graph_day;c1.push(all[l])}
                }
                else if(all[l].composition_type_id===2)
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_two){all[l].day=cur_graph.graph_day;c2.push(all[l])}}
                  else if(!all[l].razryad_short_name.includes("1 р")) if(cur_graph.compositions_sport_two){all[l].day=cur_graph.graph_day;c2.push(all[l])}
                } 
                else 
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_one){all[l].day=cur_graph.graph_day;c1.push(all[l])}}
                  else if(!all[l].razryad_short_name.includes("1 р")) if(cur_graph.compositions_sport_one){all[l].day=cur_graph.graph_day;c1.push(all[l])}
                }
        }

      }
      const result = await checkSort(c1.concat(c2).concat(c3))
      return result
    }
    else
    {
      for(let l=0;l<all.length;l++)
        {
          all[l].day=JSON.parse(graphs[0].graph_json).graph_day
        }
        const result = await checkSort(all)
        return result;
    }
      
    }
    
    function setSumScore(all)
    {
        let day1=[]
        let day2=[]
        let day3=[]
        for(let l=0;l<all.length;l++)
        {
          if(all[l].day===1)
          {
            if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              all[l].show_score=parseFloat(all[l].score)
              day1.push(all[l])
            }
            else 
            {
              all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+ parseFloat(all[l].score);
              day1.push(all[l])
            }
          }
          if(all[l].day===2)
          {
            if(day2.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
              {
                all[l].show_score=parseFloat(all[l].score)
                day2.push(all[l])
              }
              else 
              {
                  all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                  day2.push(all[l])
              }
            }
            else 
            {
              all[l].show_score=parseFloat(day2.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
              day2.push(all[l])
            }
          }
          if(all[l].day===3)
          {
            if(day3.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              if(day2.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
              {
                if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
                {
                  all[l].show_score=parseFloat(all[l].score)
                  day3.push(all[l])
                }
                else 
                {
                    all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                    day3.push(all[l])
                }
              }
              else 
              {
                all[l].show_score=parseFloat(day2.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                day3.push(all[l])
              }
            }
            else {
              all[l].show_score=parseFloat(day3.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
              day3.push(all[l])
            }
          }
        } 
        return all 
    }      

      function setMarcs()
      {
        let Marcs=[]
        for(let i=1;i<=potocs.maxpotocs;i++)
        {
          if(potocs.maxpotocs%i===0)
          {
            let Marc=
            {
              value:Math.ceil(potocs.maxpotocs/i),
              label:Math.ceil(potocs.maxpotocs/i)
            }
            Marcs.push(Marc)
          }
        }
        setPotocMarcs(Marcs)
      }

      function setTime(day)
      {
        let setOpenParadMark=false;
        let setNagradParadMark=false;
        let time=new Date();
        const result=[];
        if(day.length>0)
        {
        time.setHours(new Date(currentday.start_time).getHours());
        time.setMinutes(new Date(currentday.start_time).getMinutes());
        time.setSeconds(0)
         for(let l=0;l<day.length;l++)
                { 
                  day[l].Number=l+1;
                  day[l].afterOpenParad=false;
                  day[l].OpenParadAfterMe=false;     
                  day[l].afterNagradParad=false;
                  day[l].NagradParadAfterMe=false; 
                  day[l].Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
                  if(potocs.check)
                  {
                        let compositioin=setPotoc(day[l])
                        day[l].Time=compositioin.Time
                        day[l].potoc=compositioin.potoc
                  } 
                  if(groups_potoc.check)
                  {
                        let compositioin=setGroupsPotocs(day[l])
                        day[l].Time=compositioin.Time
                        day[l].potoc=compositioin.potoc
                  }
                  if(header_potoc.check)
                    {
                          let compositioin=setHeadersPotocs(day[l])
                          day[l].Time=compositioin.Time
                          day[l].potoc=compositioin.potoc
                    }                  
                  if(parads.check && day[l-1]?.nagrad_parad_unoshi_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_uoshi_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}:${time_member.getSeconds()}`
                  }
                  if(parads.check && day[l-1]?.nagrad_parad_sports_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_sports_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}:${time_member.getSeconds()}`
                  }
                  if(parads.check && day[l-1]?.nagrad_parad_masters_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_masters_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}${time_member.getSeconds()!==0?`:${time_member.getSeconds()}`:""}`
                  }
                  if(parads.check && !setOpenParadMark && OpenParad.check)
                  {
                        let compositioin=setTimeAfterOpenPorad(day[l])
                        day[l].Time=compositioin.Time
                        day[l].afterOpenParad=compositioin.afterOpenParad
                        if(day[l]?.afterOpenParad) setOpenParadMark=true; 
                  }
                  
                  if(parads.check && !setNagradParadMark && NagradParad.check)
                  {
                        let compositioin=setTimeAfterNagradPorad(day[l])
                        day[l].Time=compositioin.Time
                        day[l].afterNagradParad=compositioin.afterNagradParad
                        if(day[l]?.afterNagradParad) setNagradParadMark=true; 
                  }
                  let razminkatime=new Date();    
                  razminkatime.setHours(Number(day[l].Time.split(':')[0]))
                  razminkatime.setMinutes(Number(day[l].Time.split(':')[1]) - razminka.razminka_time)
                  day[l].RazminkaTime=`${razminkatime.getHours()}:${razminkatime.getMinutes()<10?"0":""}${razminkatime.getMinutes()}${razminkatime.getSeconds()!==0?`:${razminkatime.getSeconds()}`:""}`   
                 
                  let composition_time=contextValue.championat.championat_style_name==="Парно-групповое"?Number(day[l].composition_time?.split(':')[1]):Number(timeCompos.Time)
                  time.setHours(Number(day[l].Time.split(':')[0]))
                  time.setMinutes(Number(day[l].Time.split(':')[1]) + composition_time+1)
                  contextValue.championat.championat_style_name==="Парно-групповое" && time.setSeconds(Number(day[l].Time.split(':')[2])+Number(day[l].composition_time?.split(':')[2]))
                  result.push(day[l])
                }}

                if(parads.check && !setOpenParadMark && OpenParad.check){
                  if(result.length>0){
                  result[result.length-1].OpenParadAfterMe=true;
                  setOpenParadMark=true}}

                  if(parads.check && !setNagradParadMark && NagradParad.check){
                    if(result.length>0){
                    result[result.length-1].NagradParadAfterMe=true;
                    setNagradParadMark=true}}
        return result;  
      }

      async function  sortIfParad(all)
      {
        let result = [];
        if(contextValue.championat.championat_style_name!=="КПН")
        {
        if(!header_potoc.check)
          {
            let un=all.filter(x=>x.razryad_short_name.includes("юн"))
        let sport=all.filter(x=>x.razryad_short_name==="3 р" || x.razryad_short_name==="2 р")        
        let star=all.filter(x=>x.razryad_short_name==="1 р" || x.razryad_short_name.includes("МС"))
        if(parads.nadrad_parad_uoshi)
        {                    
          result=result.concat(setBrigade(un))
        }
        if(parads.nadrad_parad_sports)
        {
          if(!parads.nadrad_parad_uoshi)
          {
             sport=un.concat(sport)
          }
          result=result.concat(setBrigade(sport))
        }
        if(parads.nadrad_parad_masters)
        {
          if(!parads.nadrad_parad_sports) 
            {
              if(!parads.nadrad_parad_uoshi) 
                {
                  sport=un.concat(sport)
                }
              star=sport.concat(star)
            }
          result=result.concat(setBrigade(star))
        }
      }
      else 
      {
        let HeaderPotoc=Array.from(new Set(all.map((obj)=>obj?.HeaderPotoc)))
         
        HeaderPotoc.forEach(h => {    
          const block=setBrigade(all.filter(x=>x.HeaderPotoc===h))    
          result=result.concat((block))
        });
        
      }
    
      }
        result=result.concat(setBrigade(all.filter(el_A => !result.includes(el_A))))
        return await setRazryadPorads(result);
      }

      function setBrigade(day)
      {
        const result=[];
        if(day.length>0 && brigade)
        {
     
          if(contextValue.championat.championat_style_name==="Парно-групповое")
          {
            const two=day.filter(obj=>obj.sostav_type_name.includes('пара'));
            const more=day.filter(obj=>!obj.sostav_type_name.includes('пара'));          
            let k=two.length>more.length?two.length:more.length;
                  for(let l=0;l<k;l++)
                  {            
                    if(two.length>l)
                    {
                      two[l].Brigade=1;
                      result.push(two[l])
                    }        

                    if(more.length>l)
                    {
                      more[l].Brigade=2;
                      result.push(more[l])
                    }
                  }       
                } 
                else
                {
                  for(let l=0;l<day.length;l++)
                  {            
                    if(l%2===0)
                    {
                      day[l].Brigade=1;
                      result.push(day[l])
                    }        
                    else
                    {
                      day[l].Brigade=2;
                      result.push(day[l])
                    }
                  }
                }   
        }
        return brigade?result:day;  
      }

      async function checkSort(all)
      {
        all.forEach(e => {
          e.Header=contextValue.championat.championat_style_name==="Парно-групповое"?`${e.composition_type_name} `:""
          e.Brigade=1;
        });
        let result = all;
        if(sort.check) 
        {
          if(grouping.grouping_razryad)result =await SortByRazryad(result)
          if(grouping.grouping_group)result =await SortByGroup(result) 
          result=await setSostavType(result)   
          if(grouping.grouping_gender)result =await SortByGender(result)       
          if(grouping.grouping_age)result =await SortByAge(result)
          if(grouping.grouping_level)result =await SortByLevel(result)
          if(grouping.grouping_score)result =await SortByScore(result)
           
          if(header_potoc.check)result =await SortByGP(result)
        }
        return result;        
      }

      async function SortByGP(all)
      {
        console.log("Сортировка по бригаде")
        
        const headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        // console.log(headers)
        let result = [];
        // let header= ''
        
        all.forEach(Composition=>{
        let header= contextValue.championat.championat_style_name==="Парно-групповое"
        ?
        `${Composition.composition_type_name} ${grouping.grouping_group && sort.check?Composition.group_category_name:""} ${grouping.grouping_razryad&&sort.check?Composition.razryad_short_name+",":""} ${Composition.sostav_type_name}`
        :
        `${grouping.grouping_gender&&sort.check?Composition.sportsmen_gender==="ж"?"Девочки ":"Мальчики ":""} ${grouping.grouping_age&&sort.check?Composition.age+ " г.р. ":""}${contextValue.championat.championat_style_name!=="КПН"?grouping.grouping_razryad&&sort.check?Composition.razryad_short_name:"":grouping.grouping_level&&sort.check?Composition.kpn+" г.п.":""}`
            
        // console.log(header)        
        const newheader = HeadersCheck(header)
        // console.log(newheader)
        // console.log(headerList)
        // console.log(headerList[headerList.findIndex(x=>x.name===header)])

        Composition.HeaderPotoc=headerList[headerList.findIndex(x=>x.name===header)]?.potoc;
        })

        const HeaderPotoc=Array.from(new Set(all.map((obj)=>obj?.HeaderPotoc))).sort((a, b)=>a-b)
        // console.log(HeaderPotoc)

        HeaderPotoc.forEach(a => {
          
          let block=[] 
        headers.forEach(h => {         
          
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.HeaderPotoc===a))
          });
          result=result.concat(block)
        }); 
        return result;
      }
      function setSostavType(all)
      {
        all.forEach(e => {
          e.Header+=e.sostav_type_name!==undefined?" "+e.sostav_type_name:"";
        });
        return all;
      }



      function SortByGroup(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        let result = [];
        let groups=Array.from(new Set(all.map((obj)=>obj?.group_category_id)))

        if(sort.group_sort==="По возрастанию")
        groups=groups.sort((a, b)=>a-b)
        if(sort.group_sort==="По убыванию")
        groups=groups.sort((a, b)=>b-a)
        if(sort.group_sort==="Случайно")
        groups=shuffle(groups)

        groups.forEach(a => {
          let block=[]  
        headers.forEach(h => {
                  
          
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.group_category_id===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.group_category_name
        });
        return result;
      }

      function SortByRazryad(all)
      {

        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        let result = [];
        let razryads=Array.from(new Set(all.map((obj)=>obj?.razryad_type)))

        if(sort.razryad_sort==="По возрастанию")
        razryads=razryads.sort((a, b)=>a-b)
        if(sort.razryad_sort==="По убыванию")
        razryads=razryads.sort((a, b)=>b-a)
        if(sort.razryad_sort==="Случайно")
        razryads=shuffle(razryads)

        razryads.forEach(a => {
          let block=[]  
        headers.forEach(h => {
                  
          
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.razryad_type===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.nagrad_parad_unoshi_after_me=false;
          e.nagrad_parad_sports_after_me=false;
          e.nagrad_parad_masters_after_me=false;
          e.Header+=" "+e.razryad_short_name
        });  
        // console.log(result)      
        return result;
      }
      
      function  setRazryadPorads(result)
      {
        if(parads.nadrad_parad_uoshi)
        {
          if(result.filter(x=>x.razryad_short_name.includes("юн")).length>0)
          result.filter(x=>x.razryad_short_name.includes("юн")).at(-1).nagrad_parad_unoshi_after_me=true;
        }
        if(parads.nadrad_parad_sports)
        {
          console.log("Парад спортсменов")
          if(result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р").length>0)
          {
          result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р").at(-1).nagrad_parad_sports_after_me=true;
          // console.log(result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р").at(-1))
          }
        }
        if(parads.nadrad_parad_masters){
          if(result.filter(x=>x.razryad_short_name.includes("МС") || x.razryad_short_name==="1 р").length>0)
          result.filter(x=>x.razryad_short_name.includes("МС") || x.razryad_short_name==="1 р").at(-1).nagrad_parad_masters_after_me=true;
        }
        return result;
      }

      function SortByGender(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        //console.log(headers)
        let result = [];
        headers.forEach(e => {
          let block=[]          
          let m=all.filter(x=>x?.Header?.toString()===e).filter(x=>x.sportsmen_gender==='м')
          let g=all.filter(x=>x?.Header?.toString()===e).filter(x=>x.sportsmen_gender==='ж')
          if(sort.gender_sort==="Сначала мальчики")block=m.concat(g)
          if(sort.gender_sort==="Сначала девочки")block=g.concat(m)
          if(sort.gender_sort==="Случайно")block=shuffle(all.filter(x=>x?.Header?.toString()===e))
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=e.sportsmen_gender==="ж"?" Девочки ":" Мальчики "
        });
        return result;
      }

      function SortByAge(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))

        let result = [];
        let ages=Array.from(new Set(all.map((obj)=>obj?.age)))

        if(sort.age_sort==="По возрастанию")
        ages=ages.sort((a, b)=>a-b)
        if(sort.age_sort==="По убыванию")
        ages=ages.sort((a, b)=>b-a)
        if(sort.age_sort==="Случайно")
        ages=shuffle(ages)

        ages.forEach(a => {
          let block=[]
        headers.forEach(h => {
                    
          
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.age===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.age+" г.р."
        });
        return result;
      }

      function SortByLevel(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
         //console.log(headers)

        let result = [];
        let levels=Array.from(new Set(all.map((obj)=>obj?.kpn)))

        if(sort.level_sort==="По возрастанию")
        levels=levels.sort((a, b)=>a-b)
        if(sort.level_sort==="По убыванию")
        levels=levels.sort((a, b)=>b-a)
        if(sort.level_sort==="Случайно")
        levels=shuffle(levels)


        headers.forEach(h => {
          let block=[]          
          levels.forEach(a => {
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.kpn===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.kpn+" г.п."
        });
        return result;
      }


      function shuffle(arr){
        var j, temp;
        for(var i = arr.length - 1; i > 0; i--){
          j = Math.floor(Math.random()*(i + 1));
          temp = arr[j];
          arr[j] = arr[i];
          arr[i] = temp;
        }
        return arr;
      }

      function SortByScore(all)
      {
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        let result = [];
        if(headers.length>0)
        headers.forEach(e => {
          let block=[]
          if(sort.score_sort==="По возрастанию")
          block=all.filter(x=>x?.Header?.toString()===e).sort((a, b)=>a.show_score-b.show_score)
          if(sort.score_sort==="По убыванию")
          block=all.filter(x=>x?.Header?.toString()===e).sort((a, b)=>b.show_score-a.show_score)
          if(sort.score_sort==="Случайно")
          block=shuffle(all.filter(x=>x?.Header?.toString()===e))
          result=result.concat(block)
        });
        return result;
      }

      function HeadersCheck(header)
      {
        // console.log(header)
        let potoc = 1
        if(header_potoc.headers_times.length>0)
        {
          // console.log(header_potoc.headers_times[header_potoc.headers_times.findIndex(x=>x.name===header)])
          if(header_potoc.headers_times[header_potoc.headers_times.findIndex(x=>x.name===header)]?.potoc!==undefined)
          potoc=header_potoc.headers_times[header_potoc.headers_times.findIndex(x=>x.name===header)]?.potoc
        }
         
        let newheader=
        {
          name:header,
          count:1,
          potoc:potoc
        }
        // console.log(newheader)
        let countHead = headerList.filter(x=>x.name===header).length
        if(countHead===0)
        {
          headerList.push(newheader)
          return header;
        }
        else    
        {
          headerList[headerList.findIndex(x=>x.name===header)].count++
          return '';
        }
    
      }

      function PotocCheck(potoc)
      {
        if(potocs.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }

      function HeaderPotocCheck(potoc)
      {
        if(header_potoc.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }


      function GroupPotocCheck(potoc)
      {
        if(groups_potoc.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }


    
      // const toggleDrawer = (anchor, open) => (event) => {
      //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      //     return;
      //   }
    
      //   setState({ ...state, [anchor]: open });
      // };
      const [show, setShow] = useState(false);

      // Используем useEffect для изменения состояния при загрузке
      useEffect(() => {
        const timer = setTimeout(() => {
          setShow(true);
        }, 400); // Задержка перед появлением элемента
    
        return () => clearTimeout(timer); // Очистка таймера при размонтировании
      }, []);
      
      const [selectedItem, setSelectedItem] = useState('Время начала соревнования');
      const handleListItemClick = (item) => {
        setSelectedItem(item);
    };
    const menuItems = [
      { text: 'Время начала соревнования', icon: <SettingsIcon /> },
      { text: 'Разминка', icon: <SettingsIcon /> },
      { text: 'Время выступления', icon: <SettingsIcon /> },
      { text: 'Упражнения', icon: <SettingsIcon /> },
      { text: 'Бригады', icon: <SettingsIcon /> },
      { text: 'Группировка/Сортировка', icon: <SettingsIcon /> },
      { text: 'Парады', icon: <SettingsIcon /> },
      { text: 'Потоки', icon: <SettingsIcon /> }
  ];

    const list = () => (
      <List>
          {menuItems.map((item, index) => (
              <ListItem button key={item.text} onClick={() => handleListItemClick(item.text)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography
                      variant="body1"
                      sx={{
                        fontSize: { xs: '10px', md: '14px' },
                        marginLeft: { xs: '-30px', md: '4px' }, // Установите отступ между иконкой и текстом
                      }}
                    >
                      {item.text}
                  </Typography>
              </ListItem>
          ))}
      </List>
  );
      const renderSettings = () => {
        switch (selectedItem) {
            case 'Парады':
                return <Grid container spacing={2} columns={12}>
                <Grid item xs={12} md={12}>
                <Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
                <Grid container spacing={2} columns={12}>
                <Grid item xs={12} md={12}>
                
        <FormControlLabel sx={{ width:'100%'}}
          
          control={<Checkbox checked={parads.check} onChange={(event,x)=>setParads({...parads,check:x})}/>}
          label="Парады"
          
        />
        </Grid>
        <Grid item xs={12} md={12}>
{parads.check && <>
<Grid container spacing={2} columns={12}>
<Grid item xs={12} md={12}>
<Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
<Grid container spacing={2} columns={12}>
<Grid item xs={12} md={12}>
        <FormControlLabel fullWidth sx={{width:'100%'}}
          
          control={<Checkbox checked={OpenParad.check} onChange={(event,x)=>setOpenParad({...OpenParad,check:x})}/>}
          label="Парад открытия"
          
        />
        </Grid>
        {OpenParad.check && <><Grid item xs={12} md={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <MobileTimePicker
                                    fullWidth
                                    ampm={false}
                                    value={OpenParad.showtime}
                                    label="Время начала парада"
                                    onChange={(x, event) => setOpenParad({...OpenParad,showtime:x, openParad_start_time:new Date(x).getHours()+":"+`${new Date(x).getMinutes()<10?"0":""}${new Date(x).getMinutes()}`})}/>  
        </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Продолжительность парада`}
                                    </Typography>
                                    </Stack>
                    </Grid>
        <Grid item xs={10} md={10}>
          <Slider
            sx={{m:1}}
            value={OpenParad.openParad_time}
            min={0}
            max={360}
            step={5}
            fullWidth
            valueLabelDisplay="auto"
            onChange={(event, newValue) => {setOpenParad({...OpenParad,openParad_time:newValue})}}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <Input
            value={OpenParad.openParad_time}
            size="small"
            onChange={(event) => {
              setOpenParad(
                {...OpenParad,openParad_time:event.target.value === '' ? 0 : Number(event.target.value)}
                )
            }}
            inputProps={{
              min: 5,
              max: 360,
              step: 5,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        </>}
        </Grid>
        </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
        <Paper fullWidth sx={{my:1}}>
        <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={12}>
        
                <FormControlLabel sx={{ width:'100%'}}
                  control={<Checkbox checked={NagradParad.check} onChange={(event,x)=>setNagradParad({...NagradParad,check:x})}/>}
                  label="Парад награждения по времени"
                />
                </Grid>
                {NagradParad.check && <>
                  <Grid item xs={12} md={12}>
        
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                <MobileTimePicker
                                            ampm={false}
                                            value={NagradParad.showtime}
                                            fullWidth
                                            label="Время начала парада"
                                            onChange={(x, event) =>{  setNagradParad({...NagradParad,showtime:x,nagradParad_start_time:new Date(x).getHours()+":"+`${new Date(x).getMinutes()<10?"0":""}${new Date(x).getMinutes()}`})}}/>  
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                            <Stack direction='row' justifyContent='center'>
                            <Typography
                                            variant="body1"
                                            sx={{
                                              my:1,
                                              fontFamily: 'Poppins',
                                              color: 'inherit',
                                              whiteSpace: "pre-wrap"
                                            }}>
                                            {`Продолжительность парада (мин)`}
                                            </Typography>
                                            </Stack>
                            </Grid>
        
                <Grid item xs={10} md={10}>
                  <Slider
                    sx={{m:1}}
                    value={NagradParad.nagradParad_time}
                    min={0}
                    max={360}
                    fullWidth
                    step={5}
                    valueLabelDisplay="auto"
                    onChange={(event, newValue) => {setNagradParad({...NagradParad,nagradParad_time:newValue})}}
                    aria-labelledby="input-slider"
                  />
                  </Grid>
                  <Grid item xs={2} md={2}>
                  <Input
                    value={NagradParad.nagradParad_time}
                    size="small"
                    onChange={(event) => {
                      setNagradParad(
                        {...NagradParad,nagradParad_time:event.target.value === '' ? 0 : Number(event.target.value)}
                        )
                    }}
                    inputProps={{
                      min: 5,
                      max: 360,
                      step: 5,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                
                
                </>
                }
                </Grid>
                </Paper></Grid>
        
        {grouping.grouping_razryad ? <>
        <Grid item xs={12} md={12}>
        <Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
        <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={12}>
        <FormControlLabel sx={{ width:'100%'}}
          control={<Checkbox checked={parads.nadrad_parad_uoshi} onChange={(event,x)=>setParads({...parads,nadrad_parad_uoshi:x})}/>}
          label="Парад награждения юношеских разрядов"
        /> 
        </Grid>
        {parads.nadrad_parad_uoshi && <>
        <Grid item xs={12} md={12}>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Продолжительность парада (мин)`}
                                    </Typography>
                                    </Stack>
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <Slider
            sx={{m:1}}
                        value={parads.nadrad_parad_uoshi_time}
                        min={0}
                        max={360}
                        fullWidth
                        step={5}
                        valueLabelDisplay="auto"
                        onChange={(event, newValue) => {setParads({...parads,nadrad_parad_uoshi_time:newValue})}}
                        aria-labelledby="input-slider"
                      />
                      </Grid>
                      <Grid item xs={2} md={2}>
                      <Input
                        value={parads.nadrad_parad_uoshi_time}
                        size="small"
                        onChange={(event) => {
                          setParads(
                            {...parads,nadrad_parad_uoshi_time:event.target.value === '' ? 0 : Number(event.target.value)}
                            )
                        }}
                        inputProps={{
                          min: 5,
                          max: 360,
                          step: 5,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                    
                    </>}
                    </Grid>        
        </Paper> 
        </Grid> 
        <Grid item xs={12} md={12}>
        <Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
        <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={12}>
        <FormControlLabel sx={{ width:'100%'}}
          control={<Checkbox checked={parads.nadrad_parad_sports} onChange={(event,x)=>setParads({...parads,nadrad_parad_sports:x})}/>}
          label="Парад награждения спортивных разрядов"
        /> 
        
        </Grid>
        {parads.nadrad_parad_sports && <>
        <Grid item xs={12} md={12}>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Продолжительность парада (мин)`}
                                    </Typography>
                                    </Stack>
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <Slider
            sx={{m:1}}
                        value={parads.nadrad_parad_sports_time}
                        min={0}
                        fullWidth
                        max={360}
                        step={5}
                        valueLabelDisplay="auto"
                        onChange={(event, newValue) => {setParads({...parads,nadrad_parad_sports_time:newValue})}}
                        aria-labelledby="input-slider"
                      />
                      </Grid>
                      <Grid item xs={2} md={2}>
                      <Input
                        value={parads.nadrad_parad_sports_time}
                        size="small"
                        onChange={(event) => {
                          setParads(
                            {...parads,nadrad_parad_sports_time:event.target.value === '' ? 0 : Number(event.target.value)}
                            )
                        }}
                        inputProps={{
                          min: 5,
                          max: 360,
                          step: 5,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                    
                    </>}
                    </Grid>         
        </Paper>
        </Grid> 
        <Grid item xs={12} md={12}>
        <Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
        <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={12}>
        <FormControlLabel sx={{ width:'100%'}}
          control={<Checkbox checked={parads.nadrad_parad_masters} onChange={(event,x)=>setParads({...parads,nadrad_parad_masters:x})}/>}
          label="Парад награждения старших разрядов"
        />
        
        </Grid>
        {parads.nadrad_parad_masters && <>
        <Grid item xs={12} md={12}>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Продолжительность парада (мин)`}
                                    </Typography>
                                    </Stack>
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <Slider
            sx={{m:1}}
                        value={parads.nadrad_parad_masters_time}
                        min={0}
                        max={360}
                        step={5}
                        fullWidth
                        valueLabelDisplay="auto"
                        onChange={(event, newValue) => {setParads({...parads,nadrad_parad_masters_time:newValue})}}
                        aria-labelledby="input-slider"
                      />
                      </Grid>
                      <Grid item xs={2} md={2}>
                      <Input
                        value={parads.nadrad_parad_masters_time}
                        size="small"
                        onChange={(event) => {
                          setParads(
                            {...parads,nadrad_parad_masters_time:event.target.value === '' ? 0 : Number(event.target.value)}
                            )
                        }}
                        inputProps={{
                          min: 5,
                          max: 360,
                          step: 5,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </Grid>
                    
                    </>}  
                    </Grid>
                    
                    </Paper> 
                    </Grid>     
        </>:<>
        <Grid item xs={12} md={12}>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Для добавления парадов награждения по разрядам, необходимо включить группировку по разрядам во вкладке "Группировкиа/Сртировка"`}
                                    </Typography>
                                    </Stack>
                    </Grid></>}
        </Grid></>
              }
              </Grid></Grid></Paper></Grid></Grid>
                case 'Потоки':
                  return <Grid container spacing={2} columns={12}>
                <Grid item xs={12} md={12}>
                <Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
                <Grid container spacing={2} columns={12}>
                <Grid item xs={12} md={12}>
                <Paper fullWidth sx={{ p: 1 }}>
                <FormControlLabel sx={{ width:'100%'}}
                      control={<Checkbox checked={header_potoc.check}
                      onChange={(event, x) => {
        setHeaderPotoc({ ...header_potoc, check: x });
        setGroupsPotoc({ ...groups_potoc, check: false });
        setPotocs({ ...potocs, check: false });
      }}/>}
                      label={`Комбинирование групп по потокам`}
                    />
                     
                     <Grid item xs={12} md={12}>
                    {header_potoc.check && 
                    <>                    
                    <Stack direction='row' justifyContent='center'>
                   
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              №
                            </TableCell>
                            <TableCell>
                              Группа
                            </TableCell>
                            <TableCell>
                              Кол-во
                            </TableCell>
                            <TableCell>
                              Поток
                            </TableCell>
                          </TableRow>          
                        </TableHead>
                        <TableBody>
                        {
                          headerList?.map((h,i)=>(
                          <TableRow>
                            <TableCell>
                              {i+1}
                            </TableCell>
                            <TableCell>
                              {h.name}
                            </TableCell>
                            <TableCell>
                              {h.count}
                            </TableCell>
                            <TableCell>
                              <Input type="Number" defaultValue={h.potoc} onChange={(event)=>
                                {     
                                    const newHeaderList=headerList?.map(
                                        x=>x.name===h.name
                                      ? { ...x, potoc: Number(event.target.value)}
                                      : x)   
                                      setHeaderPotoc({...header_potoc,headers_times:newHeaderList})                           
                                }}>
                              </Input>
                            </TableCell>
                          </TableRow>
                          ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                                    
                    </Stack>
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Время перерыва между потоками - `}
                                    </Typography>
                                    <Input
                                        value={header_potoc.header_potoc_time}
                                        size="small"
                                        onChange={(event) => {
                                          setHeaderPotoc({...header_potoc,header_potoc_time:event.target.value === '' ? 0 : Number(event.target.value)})}}
                                        inputProps={{
                                          min: 5,
                                          max: 60,
                                          step: 5,
                                          type: 'number',
                                          prefix:'мин',
                                          'aria-labelledby': 'input-slider',
                                        }}
                              />
                    </Stack>
        <Grid item xs={12} md={4}>
          <Slider
            sx={{m:1}}
            value={header_potoc.header_potoc_time}
            min={0}
            max={60}
            step={5}
            valueLabelDisplay="auto"
            onChange={(event, newValue) => {setHeaderPotoc({...header_potoc,header_potoc_time:newValue})}}
            aria-labelledby="input-slider"
          />
          
        </Grid>  
        </>
}</Grid></Paper></Grid> 

<Grid item xs={12} md={12}>
<Paper fullWidth sx={{ p: 1 }}>
                      <FormControlLabel sx={{ width:'100%'}}
                        control={<Checkbox checked={groups_potoc.check}
                        onChange={(event, x) => {
        setHeaderPotoc({ ...header_potoc, check: false });
        setGroupsPotoc({ ...groups_potoc, check: x });
        setPotocs({ ...potocs, check: false });
      }}/>} 
                        label={`Каждая группа в отдельный поток`}
                      />
                      
                      {groups_potoc.check && 
                      <>                    
                      <Stack direction='row' justifyContent='center'>
                      <Typography
                                      variant="body1"
                                      sx={{
                                        my:1,
                                        fontFamily: 'Poppins',
                                        color: 'inherit',
                                        whiteSpace: "pre-wrap"
                                      }}>
                                      {`Количество групп - ${headerList.length}`}
                                      </Typography>
                                      
                      </Stack>
                      <Stack direction='row' justifyContent='center'>
                      <Typography
                                      variant="body1"
                                      sx={{
                                        my:1,
                                        fontFamily: 'Poppins',
                                        color: 'inherit',
                                        whiteSpace: "pre-wrap"
                                      }}>
                                      {`Время перерыва между потоками - `}
                                      </Typography>
                                      <Input
                                          value={groups_potoc.groups_potoc_time}
                                          size="small"
                                          onChange={(event) => {
                                            setGroupsPotoc({...groups_potoc,groups_potoc_time:event.target.value === '' ? 0 : Number(event.target.value)})}}
                                          inputProps={{
                                            min: 5,
                                            max: 60,
                                            step: 5,
                                            type: 'number',
                                            prefix:'мин',
                                            'aria-labelledby': 'input-slider',
                                          }}
                                      />
                                      </Stack>
            <Slider
              sx={{m:1}}
              value={groups_potoc.groups_potoc_time}
              min={0}
              max={60}
              step={5}
              fullWidth
              valueLabelDisplay="auto"
              onChange={(event, newValue) => {setGroupsPotoc({...groups_potoc,groups_potoc_time:newValue})}}
              aria-labelledby="input-slider"
            />       
          </>
  }
  </Paper>
</Grid>
<Grid item xs={12} md={12}>
<Paper fullWidth sx={{ p: 1 }}>
                    <FormControlLabel sx={{ width:'100%'}}
                      control={<Checkbox checked={potocs.check}
                      onChange={(event, x) => {
        setHeaderPotoc({ ...header_potoc, check: false });
        setGroupsPotoc({ ...groups_potoc, check: false });
        setPotocs({ ...potocs, check: x });
      }}/>}
                      label={`Потоки по количеству (${Math.ceil(potocs.maxpotocs/potocs.count)})`}
                    />
                    
                    {potocs.check && 
                    <>                    
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Количество ${contextValue.championat.championat_style_name==="Парно-групповое"?"составов":"спортсменов"} в потоке - `}
                                    </Typography>
                                    <Input
            value={potocs.count}
            size="small"
            onChange={
              (event) => {
                setPotocs({...potocs,count:event.target.value === '' ? 0 : Number(event.target.value)})}}
            marks={potocMarcs}
            inputProps={{
              min: 1,
              max: potocs.maxpotocs,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
          </Stack>
                    
          <Slider
            sx={{m:1}}
            fullWidth
            value={potocs.count}
            min={1}
            max={potocs.maxpotocs}
            valueLabelDisplay="auto"
            marks={potocMarcs}
            onChange={(event, newValue) => {setPotocs({...potocs,count:newValue})}}
            aria-labelledby="input-slider"
          />
          
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`Время перерыва между потоками - `}
                                    </Typography>
                                    <Input
                                        value={potocs.TimeOut}
                                        size="small"
                                        onChange={(event) => {
                                          setPotocs({...potocs,TimeOut:event.target.value === '' ? 0 : Number(event.target.value)})}}
                                        inputProps={{
                                          min: 5,
                                          max: 60,
                                          step: 5,
                                          type: 'number',
                                          prefix:'мин',
                                          'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                    </Stack>
          <Slider
            sx={{m:1}}
            value={potocs.TimeOut}
            min={0}
            max={60}
            step={5}
            fullWidth
            valueLabelDisplay="auto"
            onChange={(event, newValue) => {setPotocs({...potocs,TimeOut:newValue})}}
            aria-labelledby="input-slider"
          />  
        </>
        
}
</Paper>
</Grid>
</Grid>

</Paper></Grid></Grid>
case 'Группировка/Сортировка': 
return <Grid container spacing={2} columns={12}>
<Grid item xs={12} md={12}>
<Paper fullWidth sx={{ p: 1, display: 'flex', justifyContent: 'flex-start' }}>
<Grid container spacing={2} columns={12}>
<Grid item xs={12} md={12}>
<FormControlLabel sx={{ width:'100%'}}
  control={<Checkbox checked={sort.check}
    onChange={(event,x)=>setSort({...sort,check:x})}/>}
  label={`Группировка`}
/> 
</Grid>
<Grid item xs={12} md={12}>
{sort.check &&
<>
{contextValue.championat.championat_style_name!=="КПН"&&<Paper fullWidth sx={{ my: 1 }}>     


<FormControlLabel sx={{p:1, width:'100%'}}
  control={<Checkbox checked={grouping.grouping_razryad}
    onChange={(event,x)=>setGrouping({...grouping,grouping_razryad:x})}/>}
  label={`По разрядам`}
/>           
{grouping.grouping_razryad && <ToggleButtonGroup
            color="primary"
            value={sort.razryad_sort}
            exclusive
            label="По разрядам"
            onChange={(event, newValue)=>setSort({...sort,razryad_sort:newValue})}
            aria-label="Platform"
            sx={{
              my:1,
                display: 'grid',                    
                gridAutoColumns: '1fr',
                gridTemplateColumns: 'repeat(3, 1fr)'            
            }}
            >
            <ToggleButton value='По возрастанию'><Typography
                variant="caption"
                sx={{
                  fontFamily: 'Poppins',
                  color: 'inherit',
                  whiteSpace: "pre-wrap"
                }}>
                От Юношей к Мастерам
                </Typography>
                </ToggleButton>
            <ToggleButton value='По убыванию'><Typography
                variant="caption"
                sx={{
                  fontFamily: 'Poppins',
                  color: 'inherit',
                  whiteSpace: "pre-wrap"
                }}>От Мастеров к Юношам</Typography></ToggleButton>
                <ToggleButton value='Случайно'><Typography
                variant="caption"
                sx={{
                  fontFamily: 'Poppins',
                  color: 'inherit',
                  whiteSpace: "pre-wrap"
                }}>Случайно</Typography></ToggleButton>
    </ToggleButtonGroup>}
    </Paper>}
    {contextValue.championat.championat_style_name!=="КПН"&&<Paper fullWidth sx={{ my: 1 }}>
    <FormControlLabel sx={{p:1, width:'100%'}}
        control={<Checkbox checked={grouping.grouping_group}
        onChange={(event,x)=>setGrouping({...grouping,grouping_group:x})}/>}
        label={`По возрастным группам`}
      />     
    {grouping.grouping_group&&<ToggleButtonGroup
    color="primary"
    value={sort.group_sort}
    exclusive
    label="По возрастным группам"
    onChange={(event, newValue)=>setSort({...sort,group_sort:newValue})}
    aria-label="Platform"
    sx={{
      my:1,
        display: 'grid',                    
        gridAutoColumns: '1fr',
        gridTemplateColumns: 'repeat(3, 1fr)'            
    }}
    >
    <ToggleButton value='По возрастанию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>
        По возрастанию
        </Typography>
        </ToggleButton>
    <ToggleButton value='По убыванию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>По убыванию</Typography></ToggleButton>
         <ToggleButton value='Случайно'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Случайно</Typography></ToggleButton>
  </ToggleButtonGroup>}
  </Paper>}
    <Paper fullWidth sx={{ my: 1 }}>
    <FormControlLabel sx={{p:1, width:'100%'}}
        control={<Checkbox checked={grouping.grouping_score}
        onChange={(event,x)=>setGrouping({...grouping,grouping_score:x})}/>}
        label={`По результатам`}
      />     
    {grouping.grouping_score&&<ToggleButtonGroup
    color="primary"
    value={sort.score_sort}
    exclusive
    label="По результатам"
    onChange={(event, newValue)=>setSort({...sort,score_sort:newValue})}
    aria-label="Platform"
    sx={{
      my:1,
        display: 'grid',                    
        gridAutoColumns: '1fr',
        gridTemplateColumns: 'repeat(3, 1fr)'            
    }}
    >
    <ToggleButton value='По возрастанию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>
        По возрастанию
        </Typography>
        </ToggleButton>
    <ToggleButton value='По убыванию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>По убыванию</Typography></ToggleButton>
         <ToggleButton value='Случайно'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Случайно</Typography></ToggleButton>
  </ToggleButtonGroup>}
  </Paper>
  {contextValue.championat.championat_style_name==="КПН"&&<Paper fullWidth sx={{ my: 1 }}>
  <FormControlLabel sx={{p:1, width:'100%'}}
  control={<Checkbox checked={grouping.grouping_gender}
    onChange={(event,x)=>setGrouping({...grouping,grouping_gender:x})}/>}
  label={`По полу`}
/>
{grouping.grouping_gender&&<ToggleButtonGroup
    color="primary"
    value={sort.gender_sort}
    exclusive
    label="По результатам"
    onChange={(event, newValue)=>setSort({...sort,gender_sort:newValue})}
    aria-label="Platform"
    sx={{
      my:1,
        display: 'grid',                    
        gridAutoColumns: '1fr',
        gridTemplateColumns: 'repeat(3, 1fr)'            
    }}
    >
    <ToggleButton value='Сначала мальчики'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>
        Сначала мальчики
        </Typography>
        </ToggleButton>
    <ToggleButton value='Сначала девочки'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Сначала девочки</Typography></ToggleButton>
         <ToggleButton value='Случайно'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Случайно</Typography></ToggleButton>
  </ToggleButtonGroup>}
  </Paper>}
  {contextValue.championat.championat_style_name==="КПН"&&<Paper fullWidth sx={{ my: 1 }}>
<FormControlLabel sx={{p:1, width:'100%'}}
  control={<Checkbox checked={grouping.grouping_age}
    onChange={(event,x)=>setGrouping({...grouping,grouping_age:x})}/>}
  label={`По году рождения`}
/>
{grouping.grouping_age&&<ToggleButtonGroup
    color="primary"
    value={sort.age_sort}
    exclusive
    label="По результатам"
    onChange={(event, newValue)=>setSort({...sort,age_sort:newValue})}
    aria-label="Platform"
    sx={{
      my:1,
        display: 'grid',                    
        gridAutoColumns: '1fr',
        gridTemplateColumns: 'repeat(3, 1fr)'            
    }}
    >
    <ToggleButton value='По возрастанию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>
        По возрастанию
        </Typography>
        </ToggleButton>
    <ToggleButton value='По убыванию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>По убыванию</Typography></ToggleButton>
         <ToggleButton value='Случайно'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Случайно</Typography></ToggleButton>
  </ToggleButtonGroup>
}
</Paper>}
 {contextValue.championat.championat_style_name==="КПН"&&<Paper fullWidth sx={{ my: 1 }}>
<FormControlLabel sx={{p:1, width:'100%'}}
  control={<Checkbox checked={grouping.grouping_level}
    onChange={(event,x)=>setGrouping({...grouping,grouping_level:x})}/>}
  label={`По году подготовки`}
/>
{grouping.grouping_level&&<ToggleButtonGroup
    color="primary"
    value={sort.level_sort}
    exclusive
    label="По результатам"
    onChange={(event, newValue)=>setSort({...sort,level_sort:newValue})}
    aria-label="Platform"
    sx={{
      my:1,
        display: 'grid',                    
        gridAutoColumns: '1fr',
        gridTemplateColumns: 'repeat(3, 1fr)'            
    }}
    >
    <ToggleButton value='По возрастанию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>
        По возрастанию
        </Typography>
        </ToggleButton>
    <ToggleButton value='По убыванию'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>По убыванию</Typography></ToggleButton>
         <ToggleButton value='Случайно'><Typography
        variant="caption"
        sx={{
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap"
        }}>Случайно</Typography></ToggleButton>
  </ToggleButtonGroup>}
  </Paper>}
  </>
  }
</Grid> </Grid></Paper> </Grid></Grid>

case 'Упражнения': 
return <Paper fullWidth
sx={{
  width:'100%',
    display: 'grid',                    
    gridAutoColumns: '1fr',
    gap: 2,
    gridTemplateColumns: 'repeat(1, 1fr)',
    alignContent:'center',
    alignItems:'center',
    padding:1              
}}>
<FormControlLabel sx={{ width:'100%'}}
  control={<Checkbox checked={compositions.check} onChange={(event,x)=>setCompositions({...compositions,check:x})}/>}
  label="Упражнения"
/>
 {compositions.check && <>
                      <TableContainer component={Paper}>
                      <Table size="small" aria-label="simple table">
                          <TableRow>
                            <TableCell align="center" sx={{width:'25%'}}></TableCell> 
                            <TableCell align="center" sx={{width:'25%'}}>1</TableCell>
                            <TableCell align="center" sx={{width:'25%'}}>2</TableCell>
                            <TableCell align="center" sx={{width:'25%'}}>3</TableCell>
                            </TableRow>
                            <TableBody sx={{width:'100%'}}>
                            <TableRow sx={{width:'100%'}}>
                            <TableCell align="center" sx={{width:'25%'}}>ЮН</TableCell> 
                            <TableCell align="center" sx={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_unoshi && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.unoshi} onChange={(event,x)=>setCompositions({...compositions,unoshi:x})}/>}
                                />
                            </TableCell>
                            <TableCell align="center" sx={{width:'25%'}}></TableCell>
                            <TableCell align="center" sx={{width:'25%'}}></TableCell>  
                            </TableRow>
                            <TableRow>
                            <TableCell align="center" sx={{width:'25%'}}>СПОРТ</TableCell>
                            <TableCell align="center" sx={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_sport_one && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.sport_one} onChange={(event,x)=>setCompositions({...compositions,sport_one:x})}/>}
                                />
                            </TableCell>
                            <TableCell align="center" sx={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_sport_two && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.sport_two} onChange={(event,x)=>setCompositions({...compositions,sport_two:x})}/>}
                                />
                            </TableCell> 
                            <TableCell align="center" sx={{width:'25%'}}></TableCell> 
                            </TableRow> 
                            <TableRow>
                            <TableCell align="center" style={{width:'25%'}}>СТАРШ</TableCell> 
                            <TableCell align="center" style={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_one && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.masters_one} onChange={(event,x)=>setCompositions({...compositions,masters_one:x})}/>}
                                />
                            </TableCell>
                            <TableCell align="center" style={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_two && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.masters_two} onChange={(event,x)=>setCompositions({...compositions,masters_two:x})}/>}
                                />
                            </TableCell>
                            <TableCell align="center" width='25%' style={{width:'25%'}}>
                                <FormControlLabel sx={{ width:'100%'}}
                                control={<Checkbox disabled={graphs?.filter(x=>JSON.parse(x.graph_json).compositions_masters_three && x.graph_day!==Number(currentday.day)).length>0} checked={compositions.masters_three} onChange={(event,x)=>setCompositions({...compositions,masters_three:x})}/>}
                                />
                            </TableCell>
                            </TableRow>                                       
                            </TableBody>                                    
                            </Table>
                            </TableContainer>
                      </>}</Paper> 

case 'Разминка':   
return  <Paper
fullWidth
sx={{
  width: '100%',
  display: 'grid',
  gridAutoColumns: '1fr',
  gap: 2,
  gridTemplateColumns: 'repeat(1, 1fr)',
  alignContent: 'center',
  alignItems: 'center',
  padding: 1,
}}
>
<FormControlLabel
  sx={{ width: '100%' }}
  control={
    <Checkbox
      checked={razminka.check}
      onChange={(event, x) => setRazminka({ ...razminka, check: x })}
    />
  }
  label={`Разминка`}
/>
{razminka.check && (
  <>
    <Stack direction='row' justifyContent='center'>
      <Typography
        variant="body1"
        sx={{
          my: 1,
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap",
        }}
      >
        {`Время на разминку - `}
      </Typography>
      <Input
        value={razminka.razminka_time}
        size="small"
        onChange={(event) => {
          setRazminka({ ...razminka, razminka_time: event.target.value === '' ? 0 : Number(event.target.value) });
        }}
        inputProps={{
          min: 5,
          max: 60,
          step: 5,
          type: 'number',
          prefix: 'мин',
          'aria-labelledby': 'input-slider',
        }}
      />
    </Stack>
    <Box sx={{ width: '100%', m: 1 }}>
      <Slider
        value={razminka.razminka_time}
        min={0}
        max={60}
        step={5}
        valueLabelDisplay="auto"
        onChange={(event, newValue) => { setRazminka({ ...razminka, razminka_time: newValue }) }}
        aria-labelledby="input-slider"
      />
    </Box>
  </>
)}
</Paper>

case 'Время выступления':   
return  <Paper
fullWidth
sx={{
  width: '100%',
  display: 'grid',
  gridAutoColumns: '1fr',
  gap: 2,
  gridTemplateColumns: 'repeat(1, 1fr)',
  alignContent: 'center',
  alignItems: 'center',
  padding: 1,
}}
>
<FormControlLabel
  sx={{ width: '100%' }}
  control={
    <Checkbox
      checked={timeCompos.check}
      onChange={(event, x) => setTimeCompos({ ...timeCompos, check: x })}
    />
  }
  label={`Время выступления`}
/>
{timeCompos.check && (
  <>
    <Stack direction='row' justifyContent='center'>
      <Typography
        variant="body1"
        sx={{
          my: 1,
          fontFamily: 'Poppins',
          color: 'inherit',
          whiteSpace: "pre-wrap",
        }}
      >
        {`Время на выступление - `}
      </Typography>
      <Input
        value={timeCompos.Time}
        size="small"
        onChange={(event) => {
          setTimeCompos({ ...timeCompos, Time: event.target.value === '' ? 0 : Number(event.target.value) });
        }}
        inputProps={{
          min: 5,
          max: 60,
          step: 5,
          type: 'number',
          prefix: 'мин',
          'aria-labelledby': 'input-slider',
        }}
      />
    </Stack>
    <Box sx={{ width: '100%', m: 1 }}>
      <Slider
        value={timeCompos.Time}
        min={0}
        max={60}
        step={5}
        valueLabelDisplay="auto"
        onChange={(event, newValue) => { setTimeCompos({ ...timeCompos, Time: newValue }) }}
        aria-labelledby="input-slider"
      />
    </Box>
  </>
)}
</Paper>

case 'Бригады':
return <Paper fullWidth
sx={{
  width:'100%',
    display: 'grid',                    
    gridAutoColumns: '1fr',
    gap: 2,
    gridTemplateColumns: 'repeat(1, 1fr)',
    alignContent:'center',
    alignItems:'center',
    padding:1              
}}>
                    <FormControlLabel sx={{ width:'100%'}}
                      control={<Checkbox checked={brigade}
                        onChange={(event,x)=>changeBrigade(x)}/>}
                      label={`2 Бригады`}
                    />
                    
                    </Paper>
case 'Время начала соревнования': return <Paper fullWidth
sx={{
  width:'100%',
    display: 'grid',                    
    gridAutoColumns: '1fr',
    gap: 2,
    gridTemplateColumns: 'repeat(1, 1fr)',
    alignContent:'center',
    alignItems:'center',
    padding:1              
}}
>
          
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <MobileTimePicker
                                        ampm={false}
                                        value={currentday.start_time}
                                        fullWidth
                                        label="Время начала соревнования"
                                        onChange={(x, event) =>{  setCurrentDay({...currentday,start_time:x})}}/>  
            </LocalizationProvider>
            </Paper>
            default:
              return <Typography variant="h6">Выберите элемент из меню</Typography>;
            }
          };
    return (       
        <>
        <Helmet>
        <title>Стартовый протокол</title>
        <meta name="description" content="График соревнования" />
      </Helmet>    
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton> 
                <SettingButton color="secondary" aria-label="add" onClick={()=>setState(true)}>
                  <TuneOutlinedIcon />
                </SettingButton> 
                <BackButton color="secondary" aria-label="add" 
                component={NavLink}
                to="/championat">
                  <EmojiEventsOutlinedIcon />
                </BackButton>
                {showResult&&<FormDialog title="Результат" width={'lg'} active={showResult} setActive={setShowResult} children={AddResult} orders={select} setOrders={setSelect}/>}
            <div class="project-details" > 
                <div className="max-w-full" ref={componentRef}>
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg" style={{padding:3}}>
                        {contextValue.championat_id && 
                              <Stack direction='row' justifyContent='center'>
                              <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`${contextValue.championat.championat_type_name} соревнование\n"${contextValue.championat.championat_name}"\nДаты проведения: ${contextValue.championat.championat_start_date} - ${contextValue.championat.championat_end_date}\nОрганизатор: ${contextValue.championat.school_name}\nМесто проведения: ${JSON.parse(contextValue.championat.championat_adress)?.data?.country!==null?JSON.parse(contextValue.championat.championat_adress)?.data.country:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.city!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.city}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house!==null?`,${JSON.parse(contextValue.championat.championat_adress)?.data?.house}`:""}`}
                                    </Typography>
                              </Stack>
                        }
                         <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                               
                                <Grid container spacing={1}  padding={1}>
                       
                                    {contextValue.championat.championat_style_name==="Парно-групповое" &&
                                      <Paper fullWidth
                            sx={{
                              width:'100%',
                                display: 'grid',                    
                                gridAutoColumns: '1fr',
                                gap: 2,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                alignContent:'center',
                                alignItems:'center',
                                padding:1              
                            }}
                            >
                    <Stack direction='row' justifyContent='center'>
                    <Typography
                                    variant="body1"
                                    fullWidth
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    День соревнования
                                    </Typography>
                                    </Stack>
                    <ToggleButtonGroup
                                color="primary"
                                value={currentday.day}
                                exclusive
                                onChange={(event, newValue)=>setCurrentDay({...currentday,day:newValue})}
                                aria-label="Platform"
                                sx={{
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gridTemplateColumns: 'repeat(3, 1fr)'            
                                }}
                                >
                                <ToggleButton value='1'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    1
                                    </Typography>
                                    </ToggleButton>
                                <ToggleButton value='2'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>2</Typography></ToggleButton>
                                    <ToggleButton value='3'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>3</Typography></ToggleButton>
                                  </ToggleButtonGroup>
                                  
                                    {brigade&& <><Stack direction='row' justifyContent='center'>
                          <Typography
                                    variant="body1"
                                    fullWidth
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    Бригада
                                    </Typography>
                                    </Stack>
                                  <ToggleButtonGroup
                                color="primary"
                                value={currentday.brigade}
                                exclusive
                                onChange={(event, newValue)=>setCurrentDay({...currentday,brigade:newValue})}
                                aria-label="Platform"
                                sx={{
                                    display: 'grid',                    
                                    gridAutoColumns: '1fr',
                                    gridTemplateColumns: 'repeat(3, 1fr)'            
                                }}
                                >
                                <ToggleButton value='0'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    Все
                                    </Typography>
                                    </ToggleButton>
                                <ToggleButton value='1'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>1</Typography></ToggleButton>
                                    <ToggleButton value='2'><Typography
                                    variant="caption"
                                    sx={{
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>2</Typography></ToggleButton>
                                  </ToggleButtonGroup></>
                                    }
                                  </Paper>}
                                    
                                    {!open && (currentday.brigade==='0'?graph:graph?.filter(x=>x.Brigade===Number(currentday.brigade)))?.map((o,i)=>(
                                    <>
                                   {
                                   PotocCheck(o.potoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.potoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {potocs.TimeOut} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.potoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    } 
                                    {
                                   GroupPotocCheck(o.GroupPotoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.GroupPotoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {groups_potoc.groups_potoc_time} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.GroupPotoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    }
                                    {
                                   HeaderPotocCheck(o.HeaderPotoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.HeaderPotoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {header_potoc.header_potoc_time} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.HeaderPotoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    }
                                    {
                                   o.afterOpenParad &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад открытия {OpenParad.openParad_start_time}-{OpenParad.openParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }  
                                    {

                                    o.afterNagradParad &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад награждения {NagradParad.nagradParad_start_time}-{NagradParad.nagradParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }                                                 
                                    {
                                      brigade && o.Brigade===2 &&
                                      <Grid item xs={2} md={4} >
                                      </Grid>
                                      }
                                      <Grid item xs={brigade?10:12} md={brigade?8:12} >
                                      {contextValue.championat.championat_style_name==="Парно-групповое"?
                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${o.composition_type_name} ${grouping.grouping_group && sort.check?o.group_category_name:""} ${grouping.grouping_razryad&&sort.check?o.razryad_short_name+",":""} ${o.sostav_type_name}`)}</strong>
                                        </Typography>
                                      :                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${grouping.grouping_gender&&sort.check?o.sportsmen_gender==="ж"?"Девочки ":"Мальчики ":""} ${grouping.grouping_age&&sort.check?o.age+ " г.р. ":""}${contextValue.championat.championat_style_name!=="КПН"?grouping.grouping_razryad&&sort.check?o.razryad_short_name:"":grouping.grouping_level&&sort.check?o.kpn+" г.п.":""}`)}</strong>
                                        </Typography>}
                                      </Grid>


                                      {
                                      brigade && o.Brigade===1 &&
                                      <Grid item xs={2} md={4} >
                                      </Grid>
                                      }
                                    {
                                    brigade && o.Brigade===2 &&
                                    <Grid item xs={1} md={4} >
                                    </Grid>
                                    }
                                    <Grid item xs={brigade?11:12} md={brigade?8:12} >
                                    <Slide direction={o.Brigade===1?"right":"left"} in={show} mountOnEnter unmountOnExit>
                                      <Item  elevation={1} onClick={(event) => {
                                        setSelect(o)
                                        setShowResult(true)}}>
                                      <Grid container alignItems={"stretch"} columns={12} spacing={1}>
                                      <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">                                    
                                        
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              {o.Number}
                                            </Typography>
                                        </Grid> 
                                         
                                        {(razminka.check||timeCompos.check)&&<Grid item sx={{borderRight:"1px solid"}} xs={3} sm={2} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {razminka.check&&`Разминка: ${o.RazminkaTime.endsWith(":0")?o.RazminkaTime.substr(0,5):o.RazminkaTime}\n`}{timeCompos.check&&`Выступление: ${o.Time.endsWith(":0")?o.Time.substr(0,5):o.Time}`}
                                        </Typography>  
                                        </Grid> }
                                                                        
                                        <Grid item sx={{borderRight:"1px solid"}} xs={3} sm={2} display="flex">
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems:'left', }}>{o.hover_card?.split('\n').map((hc, i) => {
    const parts = hc.split('/');
    
    // Проверяем наличие необходимых данных
    if (parts.length < 5) {
        console.warn(`Недостаточно данных в строке: ${hc}`);
        return null; // Или можно вернуть что-то другое
    }

    const [id, surname, name, patronymic, birthDate] = parts; // Добавляем imageUrl

    return (
        <Tooltip 
            key={id}
            placement="right-end" // Используйте уникальный ID или индекс i
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img 
                        src={`./uploads/sportsmens/${id}/avatar_crop.png`} 
                        alt={`${surname} ${name}`} 
                        style={{ width: '50px', height: '50px', borderRadius:'50%', marginRight: '10px' }} // Размер изображения
                    />
                    <div>
                        <Typography variant="body2">{surname} {name}</Typography>
                        <Typography variant="body2">{patronymic}</Typography>
                        <Typography variant="body2">{birthDate}</Typography>
                        <Typography variant="body2">{o?.school_name}</Typography>
                    </div>
                </div>
            }
            arrow // Добавляет стрелку к подсказке
        >
            <Typography 
                variant="body1" 
                style={{ fontSize: '12px', cursor: 'pointer', textAlign:'start' }}
            >
                {surname} {name}
            </Typography>
        </Tooltip>
    );
})}</div>
                                       
                                        </Grid>

                                        {o.score!==null && <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column"
                                            >
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.score).toFixed(2)}
                                        </Typography>
                                        </Grid>}

                                        {o.score!==null && 
                                        (contextValue.championat.championat_style_name==="Парно-групповое" &&
                                        <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column">
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.show_score).toFixed(2)}
                                              </Typography>
                                        </Grid>)}
                                        
                                        <Grid item sx={{borderRight:{xs:"0px solid",sm:"1px solid"}}} xs={1}  display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                          
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">{o.age}
                                        </Typography> 
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={3} alignItems={"center"} display="flex"
                                            justifyContent="start"
                                            flexDirection="row">
                                        <Divider sx={{ bgcolor: "black", display:{xs:'flex', sm:'none'} }} variant="middle" flexItem />
                                        <Typography variant="caption" display="block" gutterBottom  sx={{ 
                                            fontFamily: 'Poppins',
                                            fontSize:'9px',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="left">
                                              {
                                                getSwitch(o)
                                              }
                                        </Typography>
                                        </Grid>
                                        </Grid>
                                        
                                        </Item>
                                        </Slide>
                                    </Grid>
                                    {
                                    brigade && o.Brigade===1 &&
                                    <Grid item xs={1} md={4} >
                                    </Grid>
                                    }
                                    {
                                    o.OpenParadAfterMe &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад открытия {OpenParad.openParad_start_time}-{OpenParad.openParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }  
                                    {

                                    o.NagradParadAfterMe &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                             <u>Парад награждения {NagradParad.nagradParad_start_time}-{NagradParad.nagradParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    } 
                                    {o.nagrad_parad_unoshi_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения юношеских разрядов {parads.nadrad_parad_uoshi_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>} 
                                          {o.nagrad_parad_sports_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения спортивных разрядов {parads.nadrad_parad_sports_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>} 
                                          {o.nagrad_parad_masters_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения старших разрядов {parads.nadrad_parad_masters_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>}                               

                                    </> ))
                                    
                                  }</Grid>
                        </div>
                    </div>
                </div>
                <Dialog 
      fullWidth={true}
      minWidth={'650px'}
      sx={{height:'auto'}}
      style={{zIndex:'2'}}
      // width={{xs:'650px',md:'900px'}}
      maxWidth='lg'
      open={state} 
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      aria-labelledby="draggable-dialog-title"
        keepMounted
        // onClose={()=>setState(false)}
        >
          <Box
      sx={{
        display: { xs: 'flex', sm: 'none' }, // скрыть на xs, показывать на sm и больших экранах
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        sx={{ width: 50, marginBottom:'-25px', marginLeft: 'auto' }} // Обратите внимание на правильное написание
        aria-label="close"
        onClick={() => setState(false)}
      >
        <CloseIcon />
      </IconButton>
    </Box>
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Box
                            sx={{
                                display: 'flex',
                                alignContent:'flex-start',
                                alignItems:'flex-start', 
                                justifyContent:'space-between'              
                            }}
                            > 
                            <Typography
                             
                             variant="h3"
                             sx={{ my:1,
                             display: { xs: 'flex', md: 'flex' },
                             
                             fontFamily: 'Poppins',
                             textAlign:'center',
                             color: 'inherit',
                             textDecoration: 'none',
                             }}
                         >Параметры стартового протокола
                             </Typography>
                             <IconButton sx={{ width:50, display: { xs: 'none', sm: 'flex'} }} aria-label="close" onClick={()=>setState(false)}>
          <CloseIcon />
        </IconButton>
                            </Box>
          
          </DialogTitle>
          
        <DialogContent>
        <Helmet>
        <title>Стартовый протокол - параметры</title>
        <meta name="description" content="График соревнования" />
      </Helmet>    
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
              <Box fullWidth 
              // display="flex"
                  alignItems="flex-start"
                  // minHeight="100vh"
                  p={1}>
                  <Back fullWidth>  
                  {contextValue.championat.championat_style_name==="Парно-групповое" &&<>
            <Stack direction='row' justifyContent='center'>
            <Typography
                            variant="body1"
                            sx={{
                              my:1,
                              fontFamily: 'Poppins',
                              color: 'inherit',
                              whiteSpace: "pre-wrap"
                            }}>
                            День соревнования
                            </Typography>
                            </Stack>
            <ToggleButtonGroup
                        color="primary"
                        value={currentday.day}
                        exclusive
                        onChange={(event, newValue)=>setCurrentDay({...currentday,day:newValue})}
                        aria-label="Platform"
                        sx={{
                            display: 'grid',                    
                            gridAutoColumns: '1fr',
                            gridTemplateColumns: 'repeat(3, 1fr)'            
                        }}
                        >
                        <ToggleButton value='1'><Typography
                            variant="caption"
                            sx={{
                              fontFamily: 'Poppins',
                              color: 'inherit',
                              whiteSpace: "pre-wrap"
                            }}>
                            1
                            </Typography>
                            </ToggleButton>
                        <ToggleButton value='2'><Typography
                            variant="caption"
                            sx={{
                              fontFamily: 'Poppins',
                              color: 'inherit',
                              whiteSpace: "pre-wrap"
                            }}>2</Typography></ToggleButton>
                            <ToggleButton value='3'><Typography
                            variant="caption"
                            sx={{
                              fontFamily: 'Poppins',
                              color: 'inherit',
                              whiteSpace: "pre-wrap"
                            }}>3</Typography></ToggleButton>
                </ToggleButtonGroup></>}      
                  <Grid container columns={12}>
                      <Grid item xs={2} md={4} sx={{borderRight: "0.3px solid black"}}>
                     
                      {list()}  
                      </Grid>
                  <Grid alignItems="flex-start" item xs={10} md={8} sx={{p:1}}>
                  <Box fullWidth 
                  display="flex"
                  alignItems="flex-start"
                  p={1}>
                      {renderSettings()}
                      </Box>
                  </Grid>
                  
        <Grid item xs={12} md={12}>
                  <Button 
                                  variant="contained"
                                  startIcon={<SaveOutlinedIcon />} 
                                  size="large"
                                  key="addChampionat"
                                  fullWidth
                                  onClick={()=>SaveGraph()}
                                  style={{display:'flex',maxHeight: '100px', minHeight: '50px',borderRadius:0,marginBottom:'10px', marginTop:'25px'}}
                                >Сохранить</Button> 
                                </Grid>
                                </Grid> 
                                </Back> 

                </Box>
                </DialogContent>       
                </Dialog>
            </>
     );
}
 
export default ChampionatGraph;