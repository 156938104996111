
import { useState,useEffect,useContext } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Login from "./Login";
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import ImageUpload from "./ImageUpload";
import Registration from "./Registration";
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Context} from "../context";

const useStyles = makeStyles((theme) => ({
    behind: {
        position: 'absolute', // или 'relative'
        zIndex: 0, // ставим отрицательное значение для размещения позади
    },
}));

const Home = (props) => { 
const contextValue=useContext(Context)
const[itemData,setItemData] = useState([])   
const[loginWindow,loginWindowActive]=useState(false)
const[regWindow,regWindowActive]=useState(false)
const classes = useStyles();
async function handleDelete(imageName) 
{
      try {
          await axios.delete(`/image_delete/${imageName}`);
          setItemData(itemData.filter(x=>x!==imageName))
          contextValue.setTypeMsg("info")
          contextValue.setMsg(`Изображение ${imageName} успешно удалено.`)
          contextValue.setOpenAlert(true)
          // alert('Изображение успешно удалено');
      } catch (error) {
          console.error('Ошибка при удалении изображения:', error);
          alert('Произошла ошибка при удалении изображения');
      }
  }
  const handleLogOut=()=>
    {
      axios.get('/api/users/logout')
      .then(res=>
        { 
          contextValue.setAuth(false); 
          contextValue.setSuperUser(0);   
        })
        .catch(err=> console.log(err));
    }
  function DownloadImage()
    {            
        axios.get(`/files`)
        .then(res=>{setItemData(res.data)})
        .catch(err=>console.log(err)) 
    } 
    const [columns, setColumns] = useState(3);
    useEffect(() => {
      const updateColumns = () => {
        if (window.innerWidth < 600) {
          setColumns(3); // xs
        } else if (window.innerWidth < 960) {
          setColumns(4); // md
        } else {
          setColumns(5); // lg
        }
      };
      updateColumns(); // Устанавливаем начальное количество колонок

      window.addEventListener('resize', updateColumns); // Обновляем колонки при изменении размера окна
  
      return () => {
        window.removeEventListener('resize', updateColumns); // Удаляем слушатель при размонтировании
      };
    }, []);
    useEffect(()=>{DownloadImage()},[])
    return ( 
      <>
       <Helmet>
        <title>Главная</title>
        <meta name="description" content="Система автоматизации проведения соревнований по спортивной акробатике" />
      </Helmet>
      <main class="section">
      <div className={classes.behind}        >
        
      </div>
      <FormDialog title={"Авторизация"}  active={loginWindow} setActive={loginWindowActive} children={Login} />
      {regWindow&&<FormDialog title={"Регистрация"}  active={regWindow} setActive={regWindowActive} children={Registration}/>}
      <div style={{ zIndex: 1, position: 'relative' }}>
      <Box sx={{ display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
           height: '100vh' }}>
      {/* Левая часть с фоном */}
      <Box
        sx={{
          flex: 1,
          backgroundImage: 'url(/path/to/your/background/image.jpg)', // Укажите путь к изображению фона
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageList variant="masonry" cols={columns} gap={2} sx={{ height: '100%' }}>
          {itemData.map((item) => (
            <ImageListItem key={item}>
              <img
                srcSet={`./uploads/${item}?w=248&h=496&fit=crop&auto=format&dpr=2 2x`}
                src={`./uploads/${item}?w=248&h=496&fit=crop&auto=format`}
                loading="lazy"
              />
              {contextValue.super_user === 1 && (
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  position="top"
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      onClick={() => handleDelete(item)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                  actionPosition="right"
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {/* Правая часть с текстом и кнопками */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute', 
          mt:'10%'// Позиционирование для наложения
          // width: '100%',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            padding: 4,
            maxWidth: 400
          }}
        >
        <Typography variant="h3" sx={{ fontWeight: 700, letterSpacing: '.3rem', fontFamily: 'Poppins', textAlign: 'center' }}>
          ACROpro
        </Typography>
        <Typography variant="h6" sx={{ fontFamily: 'Poppins', textAlign: 'center', marginBottom: 2 }}>
          Система автоматизации проведения соревнований по спортивной акробатике
        </Typography>
        {!contextValue.auth?<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1, width: '100%', maxWidth: 400 }}>
          <Button variant="contained" onClick={() => loginWindowActive(true)}>
            Вход
          </Button>
          
          <Button variant="contained" onClick={() => regWindowActive(true)}>
            Регистрация
          </Button>
          
        </Box>
        :
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 1, width: '100%', maxWidth: 400 }}>
          <Button variant="contained" onClick={handleLogOut}>
            Выход
          </Button>
        </Box>}
        {contextValue.super_user===1&&<ImageUpload/>}
      </Box>      
    </Box>    
    </Box>
      
      </div>
      </main>
        </>
      );
}
 
export default Home;